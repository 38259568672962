import React, { useState, useEffect } from "react";
import { AppBar, Toolbar } from "@mui/material";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import { Container } from "@mui/system";

import { Toaster } from "react-hot-toast";

import { useAuth } from '../AuthContext';

import { FIREBASE_CONFIG } from "./store/types";

import PrivateRoute from "../PrivateRoute";

import Login from "../screens/Authentication/Login";
import Assets from "../screens/Pages/Assets";
import AssetsType from "../screens/Pages/AssetsType";
import AssetCosts from "../screens/Pages/AssetCosts";
import Industries from "../screens/Pages/Industries";
import Stores from "../screens/Pages/Stores";
import Pricing from "../screens/Pages/Pricing";
import Home from "../screens/Authentication/Home";
import Header from "../screens/Authentication/Header";
import VtexInventorySearch from "../screens/Pages/VtexInventory/Search";
import Proposals from "../screens/Pages/Proposals";
import ProposalForm from "../screens/Pages/Proposals/ProposalForm";
import ProposalFormView from "../screens/Pages/Proposals/ProposalFormView";
import EditAssetCosts from "../screens/Pages/AssetCosts/New";

import Loader from "../screens/Pages/Tools/Loader";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

function App() {

  const { login } = useAuth();

  const location = useLocation();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    const storedName = localStorage.getItem('name');
    if(storedUser)
      login(storedUser, storedName)

  }, [login]);

  return (
    <>
      <AppBar position="static">
          <Toolbar
            color="primary"
            style={{ display: "flex", justifyContent: "space-between", width: "100%" }}
          >
            <div style={{ width: "200px" }}>
              <img src="/logo-unlimitail.png" width={200} alt="" />
          </div>
          
          { (location.pathname !== '/') && <Header /> }

          </Toolbar>
        </AppBar>
        <Container>

          <Routes>
            <Route path="/" element={<Login />} />
          
            <Route path="/home" element={<PrivateRoute><Home firebaseApp={app} /></PrivateRoute>} />  
          
          <Route path="/assets" element={<PrivateRoute><Assets firebaseApp={app} /></PrivateRoute>} />
          
          <Route path="/asset-types" element={<PrivateRoute><AssetsType firebaseApp={app} /></PrivateRoute>} />

          <Route path="/asset-costs" element={<PrivateRoute><AssetCosts firebaseApp={app} /></PrivateRoute>} />

          <Route path="/asset-costs/new" element={<PrivateRoute><EditAssetCosts firebaseApp={app} /></PrivateRoute>} />

          <Route path="/industries" element={<PrivateRoute><Industries firebaseApp={app} /></PrivateRoute>} />

          <Route path="/tools/loader" element={<PrivateRoute><Loader firebaseApp={app} /></PrivateRoute>} />
          
          <Route
            path="/asset-costs/:param_id"
            element={<PrivateRoute><EditAssetCosts firebaseApp={app} /></PrivateRoute>}
          />

          <Route
            path="/assets/pricing"
            element={<PrivateRoute><Pricing firebaseApp={app} /></PrivateRoute>}
          />

          <Route path="/stores" element={<PrivateRoute><Stores firebaseApp={app} /></PrivateRoute>} />

          <Route
            path="/proposals"
            element={<PrivateRoute><Proposals firebaseApp={app} /></PrivateRoute>}
          />
          <Route
            path="/proposals/new"
            element={<PrivateRoute><ProposalForm firebaseApp={app} /></PrivateRoute>}
          />
          <Route
            path="/proposals/:param_proposal_id"
            element={<PrivateRoute><ProposalFormView firebaseApp={app} /></PrivateRoute>}
          />

          </Routes>

          <Toaster
            toastOptions={{
              duration: 3000,
            }}
          />
        </Container>
    </>
  );
}

export default App;
