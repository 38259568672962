import React, { useState, useEffect } from "react";

import axios from "axios";
import ErrorIcon from "@mui/icons-material/Error";

import toast, { Toaster } from "react-hot-toast";

import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Checkbox,
  FormControl,
  CircularProgress,
  Tooltip,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import {
  formatDate,
  formatMilions,
  formatCurrency,
  formatBandeiraExt,
  formatBandeira,
  REGIOES,
  getEstadosPorRegiao,
  formatDecimal,
} from "../../router/store/types";

import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import VerifiedIcon from "@mui/icons-material/Verified";
import LoupeIcon from "@mui/icons-material/Loupe";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";

function ProposalStoreGrid({ rows, assets, header, callback, edit }) {
  console.log("PROPOSAL STORE GRID PROPS", rows, assets, header);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [selectedStores, setSelectedStores] = useState(rows || []);

  const [loading, setLoading] = useState(true);
  const [loadingCheck, setLoadingCheck] = useState(false);

  const [filter, setFilter] = useState("");
  const [regiao, setRegiao] = useState("Todas");

  const [regionsFiltro, setRegionsFiltro] = useState([]);

  const [stores, setStores] = useState([]);
  const [items, setItems] = useState([]);

  const [issuesStore, setIssuesStore] = useState();
  const [issuesList, setIssuesList] = useState([]);

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setRegiao(value);
  };

  const fetchAvailability = async (store, assets) => {
    setLoadingCheck(true);

    let formData = {
      store: store,
      assets: assets,
      retailer: header.retailer,
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/store-asset/availability`,
        formData
      );

      setLoadingCheck(false);

      return response.data;
    } catch (error) {
      setLoadingCheck(false);
      toast.error(error.response.data.message);
      console.log(error);
      return error.response.data;
    }
  };

  const handleCheckAvailability = async (storeId) => {
    try {
      const response = await fetchAvailability(storeId, assets);

      const result = response?.assets?.every((item) => item.status === 1)
        ? 1
        : 2;

      const updatedAssets = response.assets.map((asset) => {
        const originalAsset = assets.find(
          (a) => a.id === asset.id || a.idTegra === asset.id
        );
        return {
          ...asset,
          type: asset.type || originalAsset?.type,
          type_desc: asset.type_desc || originalAsset?.type_desc,
        };
      });

      let _items = items.map((item) =>
        item.id === storeId
          ? { ...item, status: result, assets: updatedAssets }
          : item
      );
      setItems(_items);

      let updatedSelectedStores = selectedStores.map((store) =>
        store.id === storeId
          ? { ...store, status: result, assets: updatedAssets }
          : store
      );
      setSelectedStores(updatedSelectedStores);

      callback(updatedSelectedStores);

      return response;
    } catch (error) {
      console.error("Error fetching availability:", error);

      let _items = items.map((item) =>
        item.id === storeId
          ? { ...item, status: 3, assets: [], error: error.message }
          : item
      );
      setItems(_items);

      let updatedSelectedStores = selectedStores.map((store) =>
        store.id === storeId
          ? { ...store, status: 3, assets: [], error: error.message }
          : store
      );
      setSelectedStores(updatedSelectedStores);

      callback(updatedSelectedStores);

      toast.error(
        `Erro ao verificar disponibilidade para a loja ${storeId}. Por favor, selecione outra loja.`
      );

      return null;
    }
  };

  const handleRecheckAvailability = async () => {
    if (!assets || assets.length === 0) {
      console.error("Original assets are not available");
      return;
    }

    let adjustedAssets = issuesList
      .map((adjustedAsset) => {
        const originalAsset = assets.find(
          (a) => a.id === adjustedAsset.id || a.idTegra === adjustedAsset.id
        );

        if (!originalAsset) {
          console.error(`Original asset not found for id: ${adjustedAsset.id}`);
          return null;
        }

        const mergedAsset = { ...originalAsset, ...adjustedAsset };

        const {
          id,
          title,
          type,
          idTegra,
          last_user_update,
          last_update,
          group,
          base_price,
          qty_min,
          qty_max,
          multiplier,
          price_min_multiplier,
          price_max_multiplier,
          slope_rate,
          gmv_weight,
          transaction_weight,
          type_desc,
          start_date,
          end_date,
          qty_proposta,
        } = mergedAsset;

        return {
          id,
          title,
          type,
          idTegra,
          last_user_update,
          last_update,
          group,
          base_price,
          qty_min,
          qty_max,
          multiplier,
          price_min_multiplier,
          price_max_multiplier,
          slope_rate,
          gmv_weight,
          transaction_weight,
          type_desc,
          start_date,
          end_date,
          qty_proposta,
        };
      })
      .filter(Boolean);

    const data = await fetchAvailability(issuesStore.id, adjustedAssets);

    const result = data?.assets?.every((item) => item.status === 1) ? 1 : 2;

    if (result === 1) {
      console.log("RESULTADO", data.assets);
      toast.success("Itens ajustados.");
      setOpen(false);
      setNewDialog(false);

      let _items = items.map((item) =>
        item.id === issuesStore.id && data?.assets.length > 0
          ? { ...item, status: result, assets: data.assets }
          : item
      );
      setItems(_items);

      let updatedSelectedStores = selectedStores.map((store) => {
        if (store.id === issuesStore.id) {
          const updatedAssets = data.assets.map((asset) => {
            const originalAsset = assets.find(
              (a) => a.id === asset.id || a.idTegra === asset.id
            );
            return {
              ...asset,
              type: asset.type || originalAsset?.type,
              type_desc: asset.type_desc || originalAsset?.type_desc,
            };
          });

          return { ...store, status: result, assets: updatedAssets };
        }
        return store;
      });
      setSelectedStores(updatedSelectedStores);

      callback(updatedSelectedStores);

      if (data.assets.length === 0) {
        setIssuesStore();
      }
    } else {
      const mergedAssets = data.assets.map((asset) => {
        const originalAsset = assets.find(
          (a) => a.id === asset.id || a.idTegra === asset.id
        );
        const updatedAsset = originalAsset
          ? { ...originalAsset, ...asset }
          : asset;
        return {
          ...updatedAsset,
          type: updatedAsset.type || originalAsset?.type,
          type_desc: updatedAsset.type_desc || originalAsset?.type_desc,
        };
      });
      setIssuesList(mergedAssets);
    }
  };

  const handleButtonAvailability = async (storeId) => {
    let response = await handleCheckAvailability(storeId);

    const result = response?.assets?.every((item) => item?.status === 1)
      ? 1
      : 2;

    if (response?.assets) {
      const updatedAssets = response.assets.map((asset) => {
        const originalAsset = assets.find(
          (a) => a.id === asset.id || a.idTegra === asset.id
        );
        return {
          ...asset,
          type: asset.type || originalAsset?.type,
          type_desc: asset.type_desc || originalAsset?.type_desc,
        };
      });

      let _items = items.map((item) =>
        item.id === storeId
          ? { ...item, status: result, assets: updatedAssets }
          : item
      );
      setItems(_items);

      let updatedSelectedStores = selectedStores.map((store) =>
        store.id === storeId
          ? { ...store, status: result, assets: updatedAssets }
          : store
      );
      setSelectedStores(updatedSelectedStores);

      callback(updatedSelectedStores);
    }
  };

  const fetchAllStores = async () => {
    console.log("Loading...");

    setLoading(true);

    const assets_list = assets.map((el) => el.id);

    try {
      axios
        .get(`${apiUrl}/api/v1/store`, {
          params: {
            retailer: header.retailer,
            assets: assets_list,
          },
        })
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setStores(response.data);
          callFilter(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setStores([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchAllStores();
    };
    fetch();
  }, []);

  const callFilter = (_stores = []) => {
    let data = stores?.length === 0 ? _stores : stores;

    data = data.map((store) => {
      const { status, checked } = rows.find((s) => s.id === store.id) || {};
      return { ...store, status, checked };
    });

    // Filtro inline aplicado ao array de rows
    const filteredRows = data.filter(
      (row) =>
        (filter === "" ||
          row.id.toLowerCase().includes(filter.toLowerCase()) ||
          row.name.toLowerCase().includes(filter.toLowerCase())) &&
        (regiao === "Todas" ||
          row.region.toUpperCase() === regiao ||
          row.state.toUpperCase() === regiao)
    );

    setItems(filteredRows);

    const uniqueRegions = [...new Set(data.map((item) => item.region))];
    let regions_filtro = REGIOES.filter((el) => uniqueRegions.includes(el.id));
    setRegionsFiltro(regions_filtro);
  };

  const handleValueChange = (e, params) => {
    let newValue = Number(e.target.value);

    if (newValue % params.row.multiplier > 0) {
      newValue -= newValue % params.row.multiplier;
      e.target.value = newValue;
    }

    let value =
      Math.floor(e.target.value) > Math.floor(params.row.qty)
        ? params.row.qty
        : e.target.value;
    params.api.setEditCellValue({
      id: params.id,
      field: "qty_proposta",
      value: Math.floor(value),
    });

    let _rows = items.map((item) =>
      item.id === params.row.id ? { ...item, qty_proposta: newValue } : item
    );

    setItems(_rows);

    callback(_rows);
  };

  const handleIssues = async (row) => {
    const validatedRow = { ...row, assets: row.assets ? row.assets : [] };

    const _assets = rows?.find((el) => el.id === validatedRow.id)?.assets;

    if (!_assets) {
      toast(
        "Ocorreu um erro ao buscar ativos para essa loja, por favor, selecione outra loja ou ativos."
      );

      return;
    }
    setIssuesStore(validatedRow);
    setIssuesList(_assets);
    setNewDialog(true);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewDialog(false);
  };

  useEffect(() => {
    callFilter();
  }, [filter, regiao]);

  const sanitizeAsset = (asset) => {
    const { status, error, ...rest } = asset;
    return rest;
  };

  const handleValueChangeModal = (e, params) => {
    let newValue = Number(e.target.value);

    const multiplier = params.row.multiplier || 1;
    if (multiplier > 1 && newValue % multiplier !== 0) {
      newValue -= newValue % multiplier;
    }

    const maxQty = params.row.qty_max;
    newValue = Math.min(newValue, maxQty);

    newValue = Math.max(0, newValue);

    params.api.setEditCellValue({
      id: params.id,
      field: "qty_proposta",
      value: newValue,
    });

    const updatedIssuesList = issuesList.map((item) =>
      item.id === params.row.id ? { ...item, qty_proposta: newValue } : item
    );
    setIssuesList(updatedIssuesList);

    e.target.value = newValue;
  };

  const handleRemoveAssetFromPopup = (id) => {
    const updatedIssuesList = issuesList.filter((asset) => asset.id !== id);
    setIssuesList(updatedIssuesList);

    if (updatedIssuesList.length === 0) {
      let updatedItems = items.map((item) =>
        item.id === issuesStore.id
          ? { ...item, checked: false, status: undefined, assets: [] }
          : item
      );
      setItems(updatedItems);

      let updatedSelectedStores = selectedStores.filter(
        (store) => store.id !== issuesStore.id
      );
      setSelectedStores(updatedSelectedStores);

      callback(updatedSelectedStores);

      setOpen(false);
      setNewDialog(false);
    }
  };

  const columnsPopup = [
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === 2 ? (
              <ReportProblemIcon sx={{ color: "red", marginLeft: "10px" }} />
            ) : (
              <VerifiedIcon sx={{ color: "green", marginLeft: "10px" }} />
            )}
          </>
        );
      },
    },
    {
      field: "assetName",
      headerName: "Ativo",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Início",
      width: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "end_date",
      headerName: "Fim",
      width: 120,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 180,
    },
    {
      field: "type_desc",
      headerName: "Tipo",
      width: 100,
    },
    {
      field: "availabilityQuantity",
      headerName: "Qtd. Disponível",
      width: 150,
    },
    {
      field: "qty_proposta",
      headerName: "Qtde.",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            width: "80px",
          }}
        >
          ✏️ {params.row.qty_proposta ? params.row.qty_proposta : 0}
        </div>
      ),
      renderEditCell: (params) => (
        <TextField
          type="number"
          inputProps={{
            step: params.row.multiplier,
            min: 0,
            max: params.row.qty,
            pattern: "[0-9]*",
          }}
          value={params.value}
          onKeyDown={(event) => event.preventDefault()}
          onChange={(event) => {
            handleValueChangeModal(event, params);
          }}
        />
      ),
    },

    {
      field: "delete",
      headerName: "",
      width: 80,
      renderCell: (params) => (
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => handleRemoveAssetFromPopup(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  function CustomCheckbox({ row }) {
    const [checked, setChecked] = React.useState(row.checked || false);

    useEffect(() => {
      setChecked(row.checked || false);
    }, [row.checked]);

    const handleCheckboxChange = async (event) => {
      setChecked(event.target.checked);
      await handleSelectedRow(row.id, event.target.checked);
    };

    const handleSelectedRow = async (storeId, isChecked) => {
      let result = 0;
      let _assets = [];

      if (isChecked) {
        const response = await handleCheckAvailability(storeId);
        if (response) {
          result = response?.assets?.every((item) => item.status === 1) ? 1 : 2;
          _assets = response?.assets || [];
        } else {
          result = 3;
        }
      }

      let _items = items.map((item) =>
        item.id === storeId
          ? { ...item, status: result, checked: isChecked, assets: _assets }
          : item
      );
      setItems(_items);

      getSelected(storeId, isChecked, _items);
    };

    return (
      <Checkbox
        checked={checked}
        onChange={handleCheckboxChange}
        color="primary"
      />
    );
  }

  const getSelected = (storeId, checked, items) => {
    let updatedSelectedStores = [...selectedStores];

    updatedSelectedStores = updatedSelectedStores.filter(
      (item) => item.id !== storeId
    );

    if (checked) {
      const storeClicked = items.find((el) => el.id === storeId);
      if (storeClicked) {
        const updatedAssets = (storeClicked.assets || []).map((asset) => {
          const originalAsset = assets.find(
            (a) => a.id === asset.id || a.idTegra === asset.id
          );
          return {
            ...asset,
            type: asset.type || originalAsset?.type,
            type_desc: asset.type_desc || originalAsset?.type_desc,
          };
        });
        updatedSelectedStores.push({ ...storeClicked, assets: updatedAssets });
      }
    }

    setSelectedStores(updatedSelectedStores);

    callback(updatedSelectedStores);
  };

  const columns = [
    {
      field: "actions",
      headerName: "",
      width: 130,
      renderCell: (params) => (
        <>
          <CustomCheckbox row={params.row} />

          {params.row.checked && (
            <IconButton
              aria-label="edit"
              color="primary"
              onClick={() => handleButtonAvailability(params.row.id)}
              disabled={!params.row.checked || params.row.status === 3}
            >
              {!loadingCheck && <RemoveRedEyeIcon />}
              {loadingCheck && <HourglassTopIcon />}
            </IconButton>
          )}

          {params.row.checked && params.row.status === 2 && (
            <ReportProblemIcon
              onClick={() => handleIssues(params.row)}
              sx={{ color: "red", cursor: "pointer" }}
            />
          )}
          {params.row.checked && params.row.status === 1 && (
            <VerifiedIcon sx={{ color: "green" }} />
          )}
          {params.row.checked && params.row.status === 3 && (
            <Tooltip title="Erro ao verificar disponibilidade. Por favor, selecione outra loja.">
              <ErrorIcon sx={{ color: "orange" }} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 90,
      renderCell: (params) => formatBandeira(params.row.retailer),
    },
    {
      field: "id",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.id} - {params.row.name}
        </>
      ),
    },
    {
      field: "assets_available",
      headerName: "Ativos desta Loja",
      width: 180,
      renderCell: (params) => (
        <>
          <Tooltip title={`Esta loja possui: ${params.value}`}>
            <LoupeIcon style={{ fontSize: "16px", marginRight: "10px" }} />
          </Tooltip>{" "}
          {params.value}
        </>
      ),
    },
    {
      field: "last_gmv",
      headerName: "Último GMV",
      width: 120,
      type: "number",
      valueFormatter: (params) => formatDecimal(params.value, 0),
    },
    {
      field: "state",
      headerName: "UF",
      width: 80,
    },
    {
      field: "region",
      headerName: "Região",
      width: 80,
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "calc(100vh - 240px)",
          overflowY: "scroll",
          overflowX: "hidden",
          padding: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ width: "300px" }}>
            <FormControl fullWidth>
              <InputLabel id="lblRegiao">Região</InputLabel>
              <Select
                fullWidth
                value={regiao}
                labelId="lblRegiao"
                id="cmbRegiao"
                name="regiao"
                label="Região"
                onChange={handleChangeSelect}
              >
                <MenuItem key="all" value="Todas">
                  Todas
                </MenuItem>
                <MenuItem key="SP" value="SP">
                  São Paulo
                </MenuItem>

                {regionsFiltro.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.regiao}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: "300px" }}>
            <FormControl fullWidth sx={{ marginBottom: "5px" }}>
              <TextField
                margin="dense"
                name="id"
                label="Busca"
                type="text"
                onChange={(e) => setFilter(e.target.value)}
              />
            </FormControl>
          </div>
          <div style={{ width: "400px" }}>
            <Typography style={{ fontSize: 12, fontWeight: "bold" }}>
              {items?.length === 1
                ? `1 loja encontrada`
                : `${items?.length} lojas encontradas`}
            </Typography>
          </div>
        </div>

        <DataGrid
          rows={items}
          columns={columns}
          autoHeight
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: "Nada selecionado",
          }}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[100]}
          rowHeight={35}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
      </Box>
      
      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          {issuesStore?.id} - {issuesStore?.name} - Verifique os Ativos
          sinalizados em vermelho
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 1,
              height: "calc(100vh - 450px)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <DataGrid
              rows={issuesList}
              columns={columnsPopup}
              loading={loading}
              autoHeight
              hideFooter
              localeText={{
                noRowsLabel: "Nada encontrado",
              }}
              getRowId={(row) => row.id}
              rowHeight={30}
              sx={{
                ".MuiDataGrid-overlayWrap": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapper": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button
            onClick={(e) => {
              handleRecheckAvailability();
            }}
            color="primary"
            disabled={issuesList.some((asset) => asset.qty_proposta === 0)}
          >
            Atualizar
          </Button>
        </DialogActions>
      </Dialog>

      {/* loading fullscreen */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 1,
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
}

export default ProposalStoreGrid;
