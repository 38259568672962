import React from "react";
import { Box, IconButton } from "@mui/material";
import ZoomInMap from "@mui/icons-material/ZoomInMap";
import OpenInFull from "@mui/icons-material/OpenInFull";
import ScatterPricingOriginal from "../../../components/ScatterPricing";

const ScatterPricing = React.memo(ScatterPricingOriginal);

const ScatterPricingBox = React.memo(
  ({ isMinimized, handleMinimize, dataset, title, labels, position }) => (
    <Box
      sx={{
        position: "fixed",
        bottom: 20,
        [position]: 20,
        width: isMinimized ? 40 : 370,
        height: isMinimized ? 40 : 295,
        backgroundColor: "white",
        boxShadow: 2,
        zIndex: 1000,
        p: 2,
        overflow: "hidden",
        margin: 0,
        padding: "2px",
      }}
    >
      <IconButton
        onClick={handleMinimize}
        sx={{
          position: "absolute",
          right: isMinimized ? 3 : 10,
          top: isMinimized ? 3 : 10,
          padding: "5px",
          minWidth: "auto",
          zIndex: 1001,
        }}
      >
        {isMinimized ? <OpenInFull /> : <ZoomInMap />}
      </IconButton>
      {!isMinimized && (
        <ScatterPricing
          title={title}
          labels={labels}
          width={380}
          height={320}
          dataset={dataset}
        />
      )}
    </Box>
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.isMinimized === nextProps.isMinimized &&
      prevProps.dataset === nextProps.dataset &&
      prevProps.title === nextProps.title &&
      prevProps.labels === nextProps.labels &&
      prevProps.position === nextProps.position
    );
  }
);

export default ScatterPricingBox;
