import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useTransition,
} from "react";
import axios from "axios";
import toast from "react-hot-toast";

import InputFields from "./InputFields";
import PricingDataGrid from "./PricingDataGrid";
import ScatterPricingBox from "./ScatterPricingBox";

import {
  formatBandeira,
  formatCurrency,
  formatDecimal,
  formatPorc,
} from "../../../router/store/types";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

import { useAuth } from "../../../AuthContext";

const Pricing = ({ firebaseApp }) => {
  const { user, name } = useAuth();

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMinimized2, setIsMinimized2] = useState(false);

  const [open, setOpen] = useState(false);
  const [enableSwitch, setEnableSwitch] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [typeList, setTypeList] = useState([]);

  const [yearMonthBase, setYearMonthBase] = useState("");
  const [busca, setBusca] = useState("");

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    retailer: "",
    group: "",
    year_month: getLastMonthYear(),
    basePrice: 0,
    storeBasePrice: 100,
    priceMinMulti: 0.1,
    priceMaxMulti: 1,
    slopeRate: 100000,
    gmvWeight: 0.7,
    transactionWeight: 0.3,
  });

  const [isPending, startTransition] = useTransition();

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await handleSubmit();
    setOpen(false);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      let _formData = {
        group: formData.group,
        year_month: formData.year_month,
        basePrice: formData.basePrice,
        storeBasePrice: formData.storeBasePrice,
        priceMinMulti: formData.priceMinMulti,
        priceMaxMulti: formData.priceMaxMulti,
        slopeRate: formData.slopeRate,
        gmvWeight: formData.gmvWeight,
        transactionWeight: formData.transactionWeight.toString().replace(',', '.'),
        last_user_update: `${name} <${user}>`,
      };

      console.log("Formulário enviado:", _formData);

      let id = _formData.group;

      axios
        .put(`${apiUrl}/api/v1/asset-type-pricing/${id}`, _formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            setSearchResults(response.data);

            if (response.data.length > 0) {
              setYearMonthBase(response.data[0].year_month);
            }

            toast.success("Parâmetros atualizados com sucesso!");
          }
        })
        .catch((error) => {
          console.error("Erro ao salvar os dados:", error);
          toast.error("Erro ao salvar os dados.");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  function getLastMonthYear() {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    return `${year}${month}`;
  }

  function ConfirmDialog({ open, onClose, onConfirm }) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao confirmar, uma nova tabela de preços do ativo será gerada para
            esse mês.
            <br />
            <br />
            Confirma a criação de uma nova tabela de preços?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleMinimize2 = () => {
    setIsMinimized2(!isMinimized2);
  };

  const validateYearMonth = (input) => {
    const regex = /^\d{6}$/;
    const year = parseInt(input.toString().substring(0, 4), 10);
    const month = parseInt(input.toString().substring(4, 6), 10);

    if (regex.test(input) && year >= 2023 && month >= 1 && month <= 12) {
      return input;
    }

    return getLastMonthYear();
  };

  const handleInputGrupo = async (event) => {
    const { name, value } = event.target;

    await fetchAssetTypeData(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputRetailer = (event) => {
    const { value } = event.target;
    setFormData((prevData) => {
      return {
        ...prevData,
        'retailer': value,
        'group': ''
      };
    });

    setSearchResults([]);
    setIsMinimized(true);
    setIsMinimized2(true);
    
  };

  const handleInputChangeNum = (name, values) => {
        const { value } = values;
    
        setFormData((prevData) => {
          if (prevData[name] === value) return prevData;
    
          if (name === "gmvWeight") {
            const _num = value === "" ? 0.1 : Number(value);
            return {
              ...prevData,
              gmvWeight: _num,
              transactionWeight: parseFloat(1 - _num),
            };
          } else {
            return {
              ...prevData,
              [name]: value,
            };
          }
          return prevData;
        });
      };

  const fetchAssetTypes = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-type-available`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setTypeList(
            response.data.sort((a, b) => a.group.localeCompare(b.group))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setTypeList([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const fetchPricing = async (_formData) => {
    console.log("Loading...");

    setSearchResults([]);
    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/store-asset-all`, { params: _formData })
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);

          if (response.data.length > 0) {
            setYearMonthBase(response.data[0].year_month);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const fetchAssetTypeData = async (group) => {
    console.log("Loading...");

    setLoading(true);

    const id = group;

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-type/${id}`, {})
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          const asset_type = response.data.asset_type;

          let _formData = {
            ...formData,
            group: id,
            year_month: asset_type.last_month_gmv,
            basePrice: asset_type.base_price,
            storeBasePrice: 100,
            priceMinMulti: asset_type.price_min_multiplier,
            priceMaxMulti: asset_type.price_max_multiplier,
            slopeRate: asset_type.slope_rate,
            gmvWeight: asset_type.gmv_weight,
            transactionWeight: asset_type.transaction_weight.toString().replace(',', '.'),
          };

          console.log("Formulário enviado", _formData);

          setFormData(_formData);

          const pricing = response.data.pricing;

          if (pricing.length > 0) {
            setYearMonthBase(pricing[0].year_month);

            setSearchResults(pricing);
          } else {
            toast.error("Tabela de preços não encontrada");

            setYearMonthBase("Não encontrado");

            setSearchResults([]);
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAssetTypes();
    };

    fetch();
  }, []);

  const handleProcessPricing = async (e) => {
    const _year_month =
      formData.year_month === ""
        ? getLastMonthYear()
        : validateYearMonth(formData.year_month);
    const _formData = {
      ...formData,
      year_month: _year_month,
    };

    setFormData(_formData);

    startTransition(() => {
      fetchPricing(_formData);
    });
  };

  const filteredRows = useMemo(() => {
    return searchResults.filter(
      (row) =>
        busca === "" ||
        row.store_id.toLowerCase().includes(busca.toLowerCase()) ||
        row.store.toLowerCase().includes(busca.toLowerCase())
    );
  }, [searchResults, busca]);

  const graphData = useMemo(() => {
    return searchResults.map((el) => ({
      x: el.gmv,
      y: el.store_price,
    }));
  }, [searchResults]);

  const graphData2 = useMemo(() => {
    return searchResults.map((el) => ({
      x: el.gmv,
      y: el.price_per_asset,
    }));
  }, [searchResults]);

  const renderBandeira = useCallback(
    (params) => formatBandeira(params.value),
    []
  );

  const renderStoreCell = useCallback(
    (params) => (
      <>
        {params.row.store_id} - {params.row.store}
      </>
    ),
    []
  );

  const gmvCellStyle = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#c0392b",
  };

  const renderGMVCell = useCallback(
    (params) => <div style={gmvCellStyle}>{formatDecimal(params.value, 0)}</div>,
    []
  );

  const transactionsCellStyle = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#3498db",
  };

  const renderTransactionsCell = useCallback(
    (params) => (
      <div style={transactionsCellStyle}>{formatDecimal(params.value, 0)}</div>
    ),
    []
  );

  const storePriceCellStyle = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#090059",
  };

  const renderStorePriceCell = useCallback(
    (params) => (
      <div style={storePriceCellStyle}>{formatDecimal(params.value, 2)}</div>
    ),
    []
  );

  const columns = useMemo(
    () => [
      {
        field: "retailer",
        headerName: "Band",
        width: 50,
        renderCell: renderBandeira,
      },
      {
        field: "store",
        headerName: "Loja",
        flex: 1,
        renderCell: renderStoreCell,
      },
      {
        field: "qty",
        headerName: "Qtd",
        type: 'number',
        width: 30,
      },
      {
        field: "gmv",
        headerName: "GMV",
        width: 120,
        type: 'number',
        renderCell: renderGMVCell,
      },
      {
        field: "coefficient_gmv",
        headerName: "Índ. GMV",
        width: 70,
        type: 'number',
        valueFormatter: (params) => formatDecimal(params.value, 3),
        headerClassName: "multi-line-header",
      },
      {
        field: "transactions",
        headerName: "Trans.",
        type: 'number',
        width: 70,
        renderCell: renderTransactionsCell,
      },
      {
        field: "coefficient_transactions",
        headerName: "Índ. Trans.",
        type: 'number',
        width: 70,
        valueFormatter: (params) => formatDecimal(params.value, 3),
        headerClassName: "multi-line-header",
      },
      {
        field: "diff_gmv_trans",
        headerName: "Dif.",
        type: 'number',
        width: 70,
        valueFormatter: (params) => formatPorc(params.value * 100, 2),
      },
      {
        field: "coefficient_final",
        headerName: "Índ. Final",
        type: 'number',
        width: 70,
        valueFormatter: (params) => formatDecimal(params.value, 3),
        headerClassName: "multi-line-header",
      },
      {
        field: "store_weight",
        headerName: "Peso Loja",
        type: 'number',
        width: 70,
        valueFormatter: (params) => formatPorc(params.value, 2),
        headerClassName: "multi-line-header",
      },
      {
        field: "store_price",
        headerName: "Valor Loja",
        type: 'number',
        width: 120,
        headerClassName: "multi-line-header",
        renderCell: renderStorePriceCell,
      },
      {
        field: "price_per_asset",
        headerName: "Preço por Ativo",
        type: 'number',
        width: 120,
        headerClassName: "multi-line-header",
        renderCell: renderStorePriceCell,
      },
    ],
    [
      renderBandeira,
      renderStoreCell,
      renderGMVCell,
      renderTransactionsCell,
      renderStorePriceCell,
    ]
  );

  const showHideGraphics = (e) => {
    setEnableSwitch(e.target.checked)
    setIsMinimized(!e.target.checked)
    setIsMinimized2(!e.target.checked)
  }

  return (
    <React.Fragment>
      <h3>Precificação de Ativos</h3>

      <InputFields
        formData={formData}
        typeList={typeList}
        handleInputGrupo={handleInputGrupo}
        handleInputRetailer={handleInputRetailer}
        handleInputChangeNum={handleInputChangeNum}
        handleProcessPricing={handleProcessPricing}
        setOpen={setOpen}
        isProcessing={loading || isPending}
        busca={busca}
        setBusca={setBusca}
        yearMonthBase={yearMonthBase}
        showHideGraphics={showHideGraphics}
      />

      <PricingDataGrid
        rows={filteredRows}
        columns={columns}
        loading={loading}
      />

      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 2000,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {searchResults.length > 0 && (
        <ScatterPricingBox
          isMinimized={!enableSwitch ? true : isMinimized}
          handleMinimize={handleMinimize}
          dataset={graphData}
          title={"Índice Final"}
          labels={["GMV", "Valor Loja"]}
          position="left"
        />
      )}

      {searchResults.length > 0 && (
        <ScatterPricingBox
          isMinimized={!enableSwitch ? true : isMinimized2}
          handleMinimize={handleMinimize2}
          dataset={graphData2}
          title={"Preço por Ativo"}
          labels={["GMV", "Preço por Ativo"]}
          position="right"
        />
      )}

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </React.Fragment>
  );
};

export default Pricing;
