import React, { useContext, useState, useEffect } from "react";
import { Box, TextField, FormControl, Button } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import CryptoJS from "crypto-js";

import LoginIcon from "@mui/icons-material/Login";
import MicrosoftIcon from "@mui/icons-material/Microsoft";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";

import { useAuth } from '../../../AuthContext';

import {loginRequest} from "../../../authConfig"

import { useNavigate } from "react-router-dom";

// import {
//   getAuth,
//   signInWithCustomToken,
//   signInAnonymously,
// } from "firebase/auth";
// import { getFirestore, getDoc, doc } from "firebase/firestore";

const Login = ({ onLogin, firebaseApp }) => {

  const { instance } = useMsal();
  const { login } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [loading, setLoading] = useState(true);

  const history = useNavigate();

  const handleLogin = () => {
    instance.loginPopup(loginRequest)
      .then((response) => {
        login(response.account.username,
          response.account.name);
        
        toast.success("Login efetuado com sucesso!");

        history('/home')
      })
      .catch((error) => {
        console.error("Erro ao fazer login: ", error);
      });
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("lastEmail");
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  // const db = getFirestore(firebaseApp);

  // const getUser = async () => {
  //   try {
  //     const docRef = doc(db, "users", email);
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       return { ...data, id: email };
  //     } else {
  //       console.log("No such document!");
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error getting document: ", error);
  //     throw error;
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // setEmailError(false);
    // setPasswordError(false);

    // localStorage.setItem("lastEmail", email);

    // if (email === "") {
    //   setEmailError(true);
    //   toast.error("Verifique as credenciais.");
    //   return;
    // }
    // if (password === "") {
    //   setPasswordError(true);
    //   toast.error("Verifique as credenciais.");
    //   return;
    // }

    // if (email && password) {
    //   const data = await getUser();

    //   if (data == null) {
    //     setPasswordError(true);
    //     toast.error("Verifique as credenciais.");
    //     return;
    //   } else if (data !== null) {
    //     const hash_password = CryptoJS.SHA1(password).toString();
    //     if (data.password === hash_password) {
    //       loginSuccess(data.name, data.id);
    //     } else {
    //       setPasswordError(true);
    //       toast.error("Verifique a senha.");
    //       return;
    //     }
    //   }
    // }
  };

  return (
    <React.Fragment>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          minHeight="100vh"
        >
          <FormControl
            sx={{ marginLeft: "0px", marginBottom: "25px", minWidth: 400 }}
          >
            <h2>Log In</h2>
            <TextField
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
              variant="outlined"
              color="secondary"
              type="email"
              sx={{ mb: 3 }}
              fullWidth
              value={email}
              error={emailError}
              autoComplete={"off"}
            />
            <TextField
              label="Senha"
              onChange={(e) => setPassword(e.target.value)}
              required
              variant="outlined"
              color="secondary"
              type="password"
              value={password}
              error={passwordError}
              fullWidth
              sx={{ mb: 3 }}
              autoComplete={"new-password"}
            />

            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                style={{ width: "150px", marginRight: "10px" }}
                startIcon={<LoginIcon />}
                onClick={(e) => { toast.error('Use o login Unlimitail NET') }}
              >
                Sign in
              </Button>

              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: "250px" }}
                startIcon={<MicrosoftIcon />}
                onClick={handleLogin}
              >
                Log in Unlimitail Net
              </Button>
            </div>
          </FormControl>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default Login;
