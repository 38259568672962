import React from "react";
import { DataGrid } from "@mui/x-data-grid";

const PricingDataGrid = React.memo(({ rows, columns, loading }) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      rowHeight={30}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 100,
          },
        },
      }}
      pageSizeOptions={[100]}
      disableRowSelectionOnClick
      getRowId={(row) => row.store_id}
      localeText={{
        noRowsLabel: "Nada encontrado",
      }}
      sx={dataGridStyles}
    />
  );
});

const dataGridStyles = {
  fontSize: "0.7rem",
  "& .MuiDataGrid-cell": {
    padding: "2px 5px",
  },
  ".MuiDataGrid-overlayWrap": {
    height: "55px !important",
    fontWeight: "bold",
  },
  ".MuiDataGrid-overlayWrapperInner": {
    height: "55px !important",
    fontWeight: "bold",
  },
  ".MuiDataGrid-overlayWrapper": {
    height: "55px !important",
    fontWeight: "bold",
  },
  "& .MuiDataGrid-columnHeaders": {
    minHeight: "40px !important",
    lineHeight: "40px !important",
    maxHeight: "40px !important",
    height: "40px !important",
  },
  "& .MuiDataGrid-columnHeader": {
    padding: "0px 5px",
    margin: "0px",
    height: "40px !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontSize: "0.75rem",
  },
};

export default PricingDataGrid;
