import React, { useState, useEffect } from "react";
import axios from "axios";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import toast, { Toaster } from "react-hot-toast";
import { Box, Typography, CircularProgress } from "@mui/material";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import {
  formatCurrency,
  formatDecimal,
  formatMilions,
  formatDate,
} from "../../../../router/store/types";

function ProposalSummary({ data, callback, edit }) {
  const [pricing, setPricing] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const { param_proposal_id } = useParams();

  const fetchView = async () => {
    setLoading(true);

    try {
      console.log("fetchView", data);

      data.proposal.clientContact = data.proposal.client_contact;
      data.proposal.totalMidia = data.proposal.total_midia;

      let proposal_assets = data.assets;

      const rowsWithId = proposal_assets.map((row, index) => ({
        lineId: index + 1,
        ...row,
        start_date: dayjs(row.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(row.end_date).format("YYYY-MM-DD"),
      }));

      setPricing(rowsWithId);

      data.pricing = rowsWithId;

      setLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || error.message || "Erro desconhecido";
      toast.error("Error: " + errorMessage);
      setLoading(false);
      console.log(error);
    }
  };

  const fetchPricing = async () => {
    setLoading(true);

    const { start_date, end_date, retailer } = data.proposal;

    if (!start_date || !end_date) {
      toast.error("Por favor informar o período.");
      setLoading(false);
      return;
    }

    const year_month = getYearMonthWithMostDays(start_date, end_date);

    if (!year_month || year_month.length !== 6) {
      toast.error("Valor inválido.");
      setLoading(false);
      return;
    }

    const stores = data.stores.map((el) => el.id).join(",");

    const assetTypesSet = new Set();

    data.stores.forEach((store) => {
      store.assets.forEach((asset) => {
        const assetType = asset.type;
        if (assetType) {
          assetTypesSet.add(assetType);
        }
      });
    });

    const assetTypes = Array.from(assetTypesSet).join(",");

    let formData = {
      retailer: retailer,
      stores: stores,
      year_month: year_month,
      asset_types: assetTypes,
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/v1/store-asset/pricing`,
        formData
      );

      const responseData = response.data;
      if (apiUrl.includes("/localhost")) console.log("pricing", responseData);

      await calcPricing(responseData);

      setLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || error.message || "Erro desconhecido";
      toast.error("Error: " + errorMessage);
      setLoading(false);
      console.log(error);
    }
  };

  function sumField(dataArray, fieldName) {
    return dataArray.reduce((sum, item) => {
      // Verifica se o campo existe e é um número, caso contrário, retorna 0
      return sum + (typeof item[fieldName] === "number" ? item[fieldName] : 0);
    }, 0);
  }

  const calcPricing = async (parameters) => {
    let proposal_assets = data.stores.flatMap((store) =>
      store.assets
        .map((asset) => {
          const assetId = asset.id || asset.assetId;
          const assetType = asset.type;

          const matchedParam = parameters.find(
            (el) =>
              el.store_id === store.id &&
              el.group_id === assetType &&
              el.asset_id === assetId
          );

          if (matchedParam) {
            const _min_cogs_unit = parseFloat(matchedParam?.min_cogs_unit);
            const _max_cogs_unit = parseFloat(matchedParam?.max_cogs_unit);
            let _qty_min = parseInt(matchedParam?.qty_min);
            const _qty_max = parseInt(matchedParam?.qty_max);
            if (_qty_min === _qty_max) _qty_min = 0;
            const _qty_proposta = parseInt(asset.qty_proposta);

            const _campaign_cost =
              _max_cogs_unit -
              ((_max_cogs_unit - _min_cogs_unit) / (_qty_max - _qty_min)) *
                (_qty_proposta - _qty_min);

            return {
              ...matchedParam,
              id: store.id,
              name: store.name,
              asset_id: assetId,
              asset: asset.assetName || asset.title,
              start_date: asset.start_date || data.proposal.start_date,
              end_date: asset.end_date || data.proposal.end_date,
              qty_proposta: _qty_proposta,
              campaign_cost: _campaign_cost,
              total: _qty_proposta * matchedParam?.price_per_asset,
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null)
    );

    if (apiUrl.includes("/localhost"))
      console.log("proposal_assets", proposal_assets);

    proposal_assets = proposal_assets.map((el) => ({
      ...el,
      campaign_margin:
        el?.price_per_asset > 0
          ? ((el?.price_per_asset - el.campaign_cost) / el?.price_per_asset) *
            100
          : 0,
    }));

    data.proposal.totalMidia = sumField(proposal_assets, "total");
    data.proposal.totalCOGS = sumField(proposal_assets, "campaign_cost");

    const rowsWithId = proposal_assets.map((row, index) => ({
      lineId: index + 1,
      ...row,
    }));

    setPricing(rowsWithId);

    data.pricing = rowsWithId;

    if (callback) {
      callback(data);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (edit || param_proposal_id) await fetchView();
      else await fetchPricing();
    };

    fetch();
  }, []);

  function getYearMonthWithMostDays(startDate, endDate) {
    // Converta as datas para objetos Date
    const start = new Date(startDate);
    const end = new Date(endDate);

    let maxDays = 0;
    let maxYearMonth = "";

    // Função auxiliar para limitar as datas ao mês
    const limitDateToMonth = (date, isStart) => {
      const year = date.getFullYear();
      const month = date.getMonth();
      return isStart ? new Date(year, month, 1) : new Date(year, month + 1, 0);
    };

    // Função para contar os dias úteis entre as datas, limitando ao mês
    const countDaysInMonth = (current, end) => {
      const startOfMonth = limitDateToMonth(current, true);
      const endOfMonth = limitDateToMonth(current, false);
      const actualStart = current > startOfMonth ? current : startOfMonth;
      const actualEnd = end < endOfMonth ? end : endOfMonth;
      return (actualEnd - actualStart) / (1000 * 60 * 60 * 24) + 1;
    };

    let current = new Date(start.getFullYear(), start.getMonth(), 1);

    // Percorre mês a mês entre startDate e endDate
    while (current <= end) {
      const daysInMonth = countDaysInMonth(current, end);
      const yearMonth = `${current.getFullYear()}${String(
        current.getMonth() + 1
      ).padStart(2, "0")}`;

      if (daysInMonth > maxDays) {
        maxDays = daysInMonth;
        maxYearMonth = yearMonth;
      }

      // Ir para o próximo mês
      current.setMonth(current.getMonth() + 1);
    }

    if (!maxYearMonth) {
      return "000000";
    }

    const year = parseInt(maxYearMonth.slice(0, 4));
    const month = parseInt(maxYearMonth.slice(4, 6));

    const dateWithMostDays = new Date(year, month - 1);

    // Retorna o valor no formato yyyyMM
    const resultYear = dateWithMostDays.getFullYear();
    const resultMonth = dateWithMostDays.getMonth() + 1; // Months are 0-indexed

    const resultYearMonth = `${resultYear}${String(resultMonth).padStart(
      2,
      "0"
    )}`;

    return resultYearMonth;
  }

  const columns = [
    {
      field: "store_id",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.store_id} - {params.row.store}
        </>
      ),
    },
    {
      field: "gmv",
      headerName: "Último GMV",
      width: 90,
      type: "number",
      valueFormatter: (params) => formatDecimal(params.value, 0),
      headerClassName: "multi-line-header",
    },
    {
      field: "asset",
      headerName: "Ativo",
      width: 120,
    },
    {
      field: "asset_type",
      headerName: "Grupo",
      width: 120,
    },
    {
      field: "periodo",
      headerName: "Período",
      width: 160,
      renderCell: (params) => (
        <div style={{}}>
          {dayjs(params.row.start_date).format("DD/MM/YYYY")} a{" "}
          {dayjs(params.row.end_date).format("DD/MM/YYYY")}
        </div>
      ),
      headerClassName: "multi-line-header",
    },
    {
      field: "price_per_asset",
      headerName: "Preço por Ativo",
      type: "number",
      width: 100,
      valueFormatter: (params) => formatDecimal(params.value),
      headerClassName: "multi-line-header",
    },
    {
      field: "qty_proposta",
      headerName: "Qtde.",
      type: "number",
      width: 70,
    },
    // {
    //   //CUSTO MAX - (((CUSTO MAX - CUSTO MIN) / (QTD MAX - QTD MIN)) * (QTD CAMPANHA - QTD MIN))
    //   field: "campaign_cost",
    //   headerName: "Custo",
    //   type: 'number',
    //   width: 70,
    //   valueFormatter: (params) => formatDecimal(params.value),
    // },
    {
      //(PRECO ATIVO - CUSTO) / PRECO ATIVO
      field: "campaign_margin",
      headerName: "Margem %",
      type: "number",
      width: 70,
      valueFormatter: (params) => formatDecimal(params.value),
    },
    {
      //(PRECO ATIVO - CUSTO) / PRECO ATIVO
      field: "total_margin",
      headerName: "Margem R$",
      type: "number",
      width: 90,
      renderCell: (params) =>
        formatDecimal(
          (parseFloat(params.row.total) *
            parseFloat(params.row.campaign_margin)) /
            100,
          2
        ),
    },
    {
      field: "total",
      headerName: "Total",
      type: "number",
      width: 90,
      valueFormatter: (params) => formatDecimal(params.value),
    },
  ];

  const calcularMargemGlobal = () => {
    const total = pricing.reduce(
      (acc, item) => {
        acc.soma_total += parseFloat(item?.total) || 0;
        acc.soma_total_margin +=
          parseFloat(item?.total) * (parseFloat(item.campaign_margin) / 100) ||
          0;
        return acc;
      },
      { soma_total: 0, soma_total_margin: 0 }
    );

    const formulaResult =
      total.soma_total > 0
        ? (total.soma_total_margin / total.soma_total) * 100
        : 0;

    const resultConverted = total.soma_total * (formulaResult / 100);

    if (formulaResult < 0) {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "red",
          }}
        >
          {formatCurrency(resultConverted)} (
          {formatDecimal(formulaResult) + "%"})
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "10px",
            fontSize: "20px",
            fontWeight: "bold",
            color: "#090059",
          }}
        >
          {formatCurrency(resultConverted)} (
          {formatDecimal(formulaResult) + "%"})
        </div>
      );
    }
  };

  return (
    <>
      {/* {!edit && (
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginLeft: "10px",
          }}
        >
          <ChecklistRtlIcon color="primary" style={{ fontSize: 50, marginRight: 10 }} />
          <div>Verifique os dados da proposta</div>
        </Typography>
      )} */}

      {data && (
        <Box
          sx={{
            padding: 1,
            height: !edit ? "calc(100vh - 370px)" : "calc(100vh - 250px)",
            overflowY: "scroll",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <fieldset className="summary-field" style={{ width: "100%" }}>
              <legend>Cliente</legend>
              <div>{data.proposal.client}</div>
            </fieldset>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {data.proposal.inc_date && (
                <fieldset
                  className="summary-field"
                  style={{
                    width: "140px",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#090059",
                  }}
                >
                  <legend>Data da Proposta</legend>
                  <div
                    style={{
                      width: "50%",
                      fontSize: "16px",
                      marginRight: "10px",
                      fontWeight: "bold",
                      color: "#090059",
                    }}
                  >
                    {formatDate(data.proposal.inc_date)}
                  </div>
                </fieldset>
              )}

              <fieldset
                className="summary-field"
                style={{
                  width: "140px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#090059",
                }}
              >
                <legend>Início</legend>
                <div
                  style={{
                    width: "50%",
                    fontSize: "16px",
                    marginRight: "10px",
                    fontWeight: "bold",
                    color: "#090059",
                  }}
                >
                  {formatDate(data.proposal.start_date)}
                </div>
              </fieldset>

              <fieldset
                className="summary-field"
                style={{
                  width: "140px",
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#090059",
                }}
              >
                <legend>Fim</legend>
                <div
                  style={{
                    width: "50%",
                    fontSize: "16px",
                    marginRight: "10px",
                    fontWeight: "bold",
                    color: "#090059",
                  }}
                >
                  {formatDate(data.proposal.end_date)}
                </div>
              </fieldset>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <fieldset className="summary-field" style={{ flex: 1 }}>
              <legend>Campanha</legend>
              <div style={{ paddingBottom: "5px" }}>
                {data.proposal.campaign}
              </div>
            </fieldset>

            <fieldset className="summary-field" style={{ flex: 1 }}>
              <legend>Contato</legend>
              <div style={{ paddingBottom: "5px" }}>
                {data.proposal.clientContact
                  ? data.proposal.clientContact
                  : data.proposal.client_contact}
              </div>
            </fieldset>

            {param_proposal_id && data.proposal.inc_user_id && (
              <fieldset className="summary-field" style={{ flex: 1 }}>
                <legend>Responsável</legend>
                <div style={{ paddingBottom: "5px" }}>
                  {data.proposal.inc_user_id}
                </div>
              </fieldset>
            )}
          </div>

          {data.proposal.comments && (
            <fieldset className="summary-field">
              <legend>Observações</legend>
              <div>{data.proposal.comments}</div>
            </fieldset>
          )}

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <fieldset
              className="summary-field"
              style={{
                width: "280px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#090059",
              }}
            >
              <legend>Margem Global</legend>
              {calcularMargemGlobal()}
            </fieldset>

            <fieldset
              className="summary-field"
              style={{
                width: "200px",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#090059",
              }}
            >
              <legend>Total Proposta</legend>
              <div
                style={{
                  width: "100%",
                  textAlign: "right",
                  paddingRight: "10px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#090059",
                }}
              >
                {data.proposal.totalMidia
                  ? formatCurrency(parseFloat(data.proposal.totalMidia))
                  : data.proposal.total_midia
                  ? formatCurrency(parseFloat(data.proposal.total_midia))
                  : "0,00"}
              </div>
            </fieldset>
          </div>

          <Box sx={{ marginTop: "15px" }}>
            <DataGrid
              rows={pricing}
              columns={columns}
              autoHeight
              disableRowSelectionOnClick
              hideFooter
              localeText={{
                noRowsLabel: "Nada encontrado",
              }}
              pinnedColumns={{ left: ["loja"] }}
              getRowId={(row) => row.lineId}
              disableColumnFilter
              disableColumnSorting
              disableColumnMenu
              disableColumnSelector
              rowHeight={30}
              sx={{
                marginRight: "20px",
                ".MuiDataGrid-overlayWrap": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapper": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                fontSize: "10px", // Tamanho da fonte reduzido
                "& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell": {
                  fontSize: "12px", // Tamanho da fonte reduzido para cabeçalho e células
                  padding: "5px", // Espaçamento interno reduzido
                },
              }}
            />
          </Box>

          {/* loading fullscreen */}
          {loading && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default ProposalSummary;
