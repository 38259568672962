import React from "react";
import { Grid, TextField, Button, MenuItem,Switch,FormControlLabel } from "@mui/material";
import { BANDEIRAS, formatBandeira, formatBandeiraExt, formatDecimal } from "../../../router/store/types";
import DecimalTextField from "../../../components/DecimalTextField";

const InputFields = React.memo(
  ({
    formData,
    typeList,
    handleInputGrupo,
    handleInputRetailer,
    handleInputChangeNum,
    handleProcessPricing,
    setOpen,
    isProcessing,
    busca,
    setBusca,
    yearMonthBase,
    showHideGraphics
  }) => {
    return (
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ margin: "0px", marginBottom: "10px", padding: "0px" }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <TextField
            select
            label="Bandeira"
            name="retailer"
            fullWidth
            variant="outlined"
            value={formData.retailer}
            onChange={handleInputRetailer}
            sx={{
              "& .MuiSelect-select": {
                alignItems: "center"
              },
            }}
          >
            {BANDEIRAS.sort((a,b) => a.localeCompare(b)).map((el, index) => (
              <MenuItem
                key={index}
                value={el}
                style={{ padding: "5px !important" }}
              >
                {formatBandeiraExt(el)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>


        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <TextField
            select
            label="Grupo Ativo"
            name="group"
            fullWidth
            variant="outlined"
            value={formData.group}
            onChange={handleInputGrupo}
            disabled={formData.retailer === ""}
            sx={{
              "& .MuiSelect-select": {
                padding: "5px 10px",
                margin: "0px",
                height: "45px !important",
              },
            }}
          >
            {typeList.filter(el => el.retailer === formData.retailer).map((asset) => (
              <MenuItem
                key={asset.id}
                value={asset.id}
                style={{ padding: "5px !important" }}
              >
                <div>
                  <div style={{ fontSize: "14px" }}>{`${asset.group}`}</div>
                  <div style={{ fontSize: "12px" }}>
                    {`#${asset.id} - Qtd Lojas: ${formatDecimal(
                      asset.global_stores_qty,
                      0
                    )} - Total Ativos: ${formatDecimal(
                      asset.global_assets_qty,
                      0
                    )}`}
                  </div>
                </div>
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <TextField
            label="Último mês com GMV cadastrado"
            type="number"
            fullWidth
            variant="outlined"
            name="year_month"
            value={formData.year_month}
            disabled={true}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <DecimalTextField 
            label="Base Preço"
            type="number"
            fullWidth
            variant="outlined"
            name="basePrice"
            value={formData.basePrice}
            decimalScale={0}
            onChange={(e) => handleInputChangeNum('basePrice', e)}
            disabled={formData.group === ""}
            MIN_LIMIT={1}
            MAX_LIMIT={100000}
          />
        </Grid>

        {/* <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <TextField
            label="Base Loja"
            type="number"
            fullWidth
            variant="outlined"
            name="storeBasePrice"
            value={formData.storeBasePrice}
            onChange={handleInputChange}
            inputProps={{ step: 100, max: 1000, min: 100 }}
            disabled={formData.group === ""}
          />
        </Grid> */}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <DecimalTextField 
            label="Multiplicador Preço Mín."
            type="number"
            fullWidth
            variant="outlined"
            name="priceMinMulti"
            value={formData.priceMinMulti}
            decimalScale={2}
            onChange={(e) => handleInputChangeNum('priceMinMulti', e)}
            disabled={formData.group === ""}
            MIN_LIMIT={0.01}
            MAX_LIMIT={1000}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <DecimalTextField 
            label="Multiplicador Preço Máx."
            type="number"
            fullWidth
            variant="outlined"
            name="priceMaxMulti"
            value={formData.priceMaxMulti}
            decimalScale={2}
            onChange={(e) => handleInputChangeNum('priceMaxMulti', e)}
            disabled={formData.group === ""}
            MIN_LIMIT={0.01}
            MAX_LIMIT={1000}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <DecimalTextField 
            label="Índice Curva"
            type="number"
            fullWidth
            variant="outlined"
            name="slopeRate"
            value={formData.slopeRate}
            decimalScale={0}
            onChange={(e) => handleInputChangeNum('slopeRate', e)}
            disabled={formData.group === ""}
            MIN_LIMIT={0}
            MAX_LIMIT={1000000}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <DecimalTextField 
            label="Peso GMV"
            type="number"
            fullWidth
            variant="outlined"
            decimalScale={2}
            name="gmvWeight" 
            value={formData.gmvWeight}
            onChange={(e) => handleInputChangeNum('gmvWeight', e)}
            disabled={formData.group === ""}
            MIN_LIMIT={0.01}
            MAX_LIMIT={1}
          />

        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{ marginTop: "10px", padding: "0px 5px 0px 0px" }}
        >
          <TextField
            label="Peso Transação"
            fullWidth
            variant="outlined"
            value={formData.transactionWeight.toString().replace(".", ",")}
            InputProps={{
              readOnly: true,
              disabled: true,
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          style={{ marginTop: "10px", display: 'flex', justifyContent: "flex-end", padding: "0px 5px 0px 10px" }}
        >
          <FormControlLabel
          control={
            <Switch onChange={(e) => showHideGraphics(e)} />
          }
          label="Mostrar gráficos"
        />

        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ marginRight: "8px" }}
            onClick={() => setOpen(true)}
            disabled={formData.group === "" || isProcessing}
          >
            Salvar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleProcessPricing}
            disabled={formData.group === "" || isProcessing}
          >
            Calcular
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
            padding: "0px 5px 0px 0px",
          }}
        >
          <TextField
            label="Buscar"
            variant="outlined"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
          />
        </Grid>

        {yearMonthBase && (
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              padding: "0px 5px 0px 0px",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                textAlign: "right",
                width: "100%",
                marginRight: "20px",
              }}
            >
              <b>Ano Mês Base: </b>
              {yearMonthBase}
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
);

export default InputFields;
