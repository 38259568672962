import * as React from 'react';
import { ScatterChart } from '@mui/x-charts/ScatterChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

//#090059

const ScatterPricing = ({ title, labels, width, height, dataset }) => {
    return (
        <ScatterChart
            width={width}
            height={height}
            style={{ padding: '15px', marginTop: '-15px' }}
            series={[
                {
                    data: dataset,
                    label: title,
                    color: '#36F5A8',
                    size: 20,
                    yAxisId: 'leftAxis',
                    valueFormatter: ({ x, y }) => `${labels[0]}: ${x} - ${labels[1]}: ${y}`,
                }
            ]}
            xAxis={[{ min: 0, label: labels[1] }]}
            yAxis={[
                { id: 'leftAxis', min: 0, label: labels[0] },
                { id: 'rightAxis', min: 0, label: labels[0] },
            ]}
            rightAxis="rightAxis"
            sx={{
                [`& .${axisClasses.left}`]: {
                    line: { stroke: '#8884d8' },
                    text: { fill: '#8884d8' },
                },
                [`& .${axisClasses.right}`]: {
                    line: { stroke: '#82ca9d' },
                    text: { fill: '#82ca9d' },
                },
            }}
        />
    );
}

export default ScatterPricing
