import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Button, Box, CircularProgress } from "@mui/material";
import DoorBackIcon from "@mui/icons-material/DoorBack";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { useAuth } from "../../../../AuthContext";
import ProposalSummary from "../ProposalSummary";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import ProposalPdf from "../ProposalPdf";

export default function ProposalFormView() {
  const [edit, setEdit] = useState(false);

  const [retailer, setRetailer] = useState("");
  const [uniqueAssets, setUniqueAssets] = useState([]);
  const [uniqueStores, setUniqueStores] = useState([]);
  const [storesAssetsSelected, setStoresAssetsSelected] = useState([]);
  const [clients, setClients] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { param_proposal_id } = useParams();

  const { user, name } = useAuth();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const getDocumentById = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${apiUrl}/api/v1/proposal/${param_proposal_id}`
      );

      if (apiUrl.includes("/localhost"))
        console.log("data getdocumentbyid", response.data);

      const proposal = response.data.proposal;

      // if (proposal.inc_user_id === user)
      //   await loadDataToEdit(response.data)
      // else
      await loadDataToView(response.data);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Registro não encontrado");
        toast.error("Registro não encontrado");
      } else {
        toast.error("Erro ao acessar API");
        console.error("Erro ao buscar os dados", error.toString());
      }
    } finally {
      setLoading(false);
    }
  };

  const loadDataToView = async (_data) => {
    try {
      setLoading(true);

      _data.proposal.start_date = dayjs(_data.proposal.start_date).format(
        "YYYY-MM-DD"
      );
      _data.proposal.end_date = dayjs(_data.proposal.end_date).format(
        "YYYY-MM-DD"
      );

      _data.assets = _data.assets.map((row) => ({
        ...row,
        qty_proposta: row.qty_proposta,
        total: parseFloat(row.total),
        campaign_cost: parseFloat(row.campaign_cost),
        price_per_asset: parseFloat(row.price_per_asset),
        campaign_margin: parseFloat(row.campaign_margin),
        start_date: dayjs(row.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(row.end_date).format("YYYY-MM-DD"),
      }));

      setData(_data);
    } catch (error) {
      toast.error("Erro ao carregar os dados para visualização");
    } finally {
      setLoading(false);
    }
  };

  const loadDataToEdit = async (data) => {
    try {
      console.log("Carregando editor");

      setLoading(true);

      // const assetsData = response.data.assets;

      // // Carregando dados da proposta
      // let _values = {
      //   proposal_id: proposal.id,
      //   client_id: proposal.client_id,
      //   client: proposal.client,
      //   clientContact: proposal.client_contact,
      //   campaign: proposal.campaign,
      //   totalCOGS: parseFloat(proposal.total_cogs),
      //   totalMidia: parseFloat(proposal.total_midia),
      //   inc_user_id: proposal.inc_user_id,
      //   inc_date: proposal.inc_date,
      //   comments: proposal.comments,
      //   retailer: proposal.retailer,
      //   start_date: proposal.start_date,
      //   end_date: proposal.end_date,
      // };
      // setValues(_values);

      // const uniqueStoresList = Array.from(
      //   new Map(
      //     assetsData.map((el) => [
      //       el.store_id,
      //       { id: el.store_id, name: el.store },
      //     ])
      //   ).values()
      // );
      // setUniqueStores(uniqueStoresList);

      // const uniqueAssetsList = Array.from(
      //   new Map(
      //     assetsData.map((el) => [
      //       el.asset_id,
      //       {
      //         id: el.asset_id,
      //         title: el.asset,
      //         start_date: el.start_date,
      //         end_date: el.end_date,
      //         qty_proposta: el.qty,
      //       },
      //     ])
      //   ).values()
      // );
      // setUniqueAssets(uniqueAssetsList);

      // const _storesAssetsSelected = assetsData.map((el) => ({
      //   id: el.id,
      //   asset_id: el.asset_id,
      //   retailer: proposal.retailer,
      //   sigla: el.store_id,
      //   loja: el.store,
      //   asset: el.asset,
      //   qty_proposta: el.qty,
      //   price_per_asset: parseFloat(el.midia),
      // }));

      // setStoresAssetsSelected(_storesAssetsSelected);

      // setRetailer(proposal.retailer);
    } catch (error) {
      toast.error("Erro ao carregar os dados para edição");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      if (param_proposal_id) {
        setEdit(true);
        await getDocumentById();
      }
    };
    fetch();
  }, [param_proposal_id]);

  const redirectToStart = () => {
    navigate("/proposals");
  };

  return (
    data && (
      <>
        <h2>
          Visualização da Proposta ID <i>{param_proposal_id}</i>
        </h2>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ padding: 2 }}>
            {!loading && <ProposalSummary data={data} edit={edit} />}

            {loading && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
              }}
            >
              <Button
                sx={{ marginRight: 1 }}
                onClick={redirectToStart}
                color="error"
                variant="contained"
              >
                Sair <DoorBackIcon sx={{ marginLeft: "5px" }} />
              </Button>

              {data && (
                <div>
                  <ProposalPdf data={data} edit={edit} />
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </>
    )
  );
}
