import React, { useState, useEffect } from "react";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";

import CurrencyTextField from "../../../components/CurrencyTextField";

import { useAuth } from '../../../AuthContext'; 

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import {
  BANDEIRAS,
  TIPOS_ATIVOS,
  formatBandeira,
  formatCurrency,
  formatDecimal,
  hasFullPermission
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const vet_bandeiras = BANDEIRAS;

const Assets = () => {
  const theme = useTheme();

  const { user, name } = useAuth()

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [filter, setFilter] = useState("");
  const [retailer, setRetailer] = useState("Todas");

  const [typeList, setTypeList] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const fetchTypes = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-type`)
        .then((response) => {
          // if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setTypeList(response.data.map(el => ({ id: el.id, group: el.group })));
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setTypeList([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const callFilter = async (_data = []) => {

    let _rows = _data.length > 0 ? _data : searchResults

    // Filtro inline aplicado ao array de rows
    const filteredRows = _rows.filter(
      (row) =>
      (filter === "" ||
        row.group.toLowerCase().includes(filter.toLowerCase()) || 
        row.type_desc.toLowerCase().includes(filter.toLowerCase()) ||
        row.title?.toLowerCase().includes(filter.toLowerCase())) &&
        ((retailer === "Todas" ||
          row.retailer.toString().toUpperCase() === retailer.toUpperCase()))
    );

    setRows(filteredRows.sort((a, b) => a.title.localeCompare(b.title)))
    
  }

  useEffect(() => {
    callFilter()
  }, [filter, retailer]);

  // const showConfirmation = (id, name) => {
  //   if (user !== "luiz.flores@unlimitail.com") {
  //     toast.error("Você não possui permissão para excluir!");
  //     return;
  //   }

  //   const toastId = toast.custom(
  //     (t) => (
  //       <ConfirmDialog
  //         id={id}
  //         name={name}
  //         open={true}
  //         onClose={() => toast.dismiss(t.id)} // Fecha o toast
  //       />
  //     ),
  //     { duration: Infinity }
  //   ); // Mantém o toast aberto
  // };

  const handleNewClick = async () => {
    let row = {
      id: "",
      title: "",
      type: "",
      base_price: 0,
    };

    await fetchTypes();

    setNewDialog(true);
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const handleEditClick = async (row) => {

    await fetchTypes();

    console.log('row', row)

    setCurrentRow({ ...row, group: row.type });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewDialog(false);
    setCurrentRow(null);
    reset();
  };

  const handleSave = async () => {
    if (newDialog) {

      setLoading(true);

      let formData = {
        ...currentRow,
        type: currentRow.group,
        last_user_update: `${name} <${user}>`
      }

      if (!currentRow.title || !currentRow.group) {
        toast.error("Verifique os campos obrigatórios!")
        return;
      }

      console.log("Formulário enviado:", formData);

      axios
        .post(`${apiUrl}/api/v1/asset`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 201) {
            toast.success('Ativo incluído com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
      
    } else {
      setLoading(true);

      let formData = {
        ...currentRow,
        type: currentRow.group,
        last_user_update: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .put(`${apiUrl}/api/v1/asset/${currentRow.id}`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            toast.success('Ativo atualizado com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleChange = (event, _id) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, [_id]: value }));
  };

  const handleKeyDown = (event) => {
    // Bloqueia a vírgula (,) e o ponto (.)
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
    }
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, group: value }));
  };

  const columns = [
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 80,
      editable: false,
      renderCell: (params) => formatBandeira(params.value),
    },
    {
      field: "title",
      headerName: "Ativo",
      flex: 1,
    },
    {
      field: "qty_stores",
      headerName: "Qtd Lojas",
      width: 100,
      type: 'number'
    },
    {
      field: "type_desc",
      headerName: "Tipo",
      width: 140
    },
    {
      field: "group",
      headerName: "Grupo",
      width: 180
    },
    // {
    //   field: "actions",
    //   headerName: "Ações",
    //   width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         aria-label="edit"
    //         color="primary"
    //         onClick={() => handleEditClick(params.row)}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //       <IconButton
    //         aria-label="delete"
    //         color="primary"
    //         onClick={() => showConfirmation(params.row.id, params.row.title)}
    //       >
    //         <DeleteIcon />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];

  const handleInputNumChange = (values) => {
    const { value } = values;
    setCurrentRow((prevRow) => ({ ...prevRow, 'base_price': value }));
  };

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
          setRows(response.data)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const handleChangeBandeira = (e) => {
    const { name, value } = e.target;
    setRetailer(value);
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  return (
    <React.Fragment>
      <h2>Ativos</h2>

      <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "50px",
            alignItems: "center",
            marginBottom: '10px'
          }}
        >
          <div style={{ width: '300px', marginRight: '10px' }}>
            <FormControl fullWidth sx={{ marginBottom: "8px" }}>
            <InputLabel id="lblRetailer">Bandeira</InputLabel>
            <Select
              fullWidth
              value={retailer}
              labelId="lblRetailer"
              id="cmbRetailer"
              name="retailer"
              label="Bandeira"
              onChange={handleChangeBandeira}
            >
              <MenuItem key="all" value="Todas">Todas</MenuItem>

              {BANDEIRAS.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <TextField
              margin="dense"
              name="id"
              label="Busca"
              type="text"
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
        </div>

        {/* <div
          style={{
            marginLeft: "0px",
            display: "flex",
            justifyContent: "right",
            gap: "40px",
            width: "100%",
            marginBottom: "10px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            style={{ width: "200px" }}
            startIcon={<AddIcon />}
            onClick={() => {
              handleNewClick();
            }}
          >
            Novo
          </Button>
        </div> */}

      </div>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          loading={loading}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          rowHeight={30}
          sx={{
            fontSize: '0.7rem',
            '& .MuiDataGrid-cell': {
              padding: '2px 5px',
            },
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
            '& .MuiDataGrid-columnHeaders': {
              minHeight: '40px !important',
              lineHeight: '40px !important',
              maxHeight: '40px !important',
              height: '40px !important'
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '0px 5px',
              margin: '0px',
              height: '40px !important'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '0.75rem', // Diminui o tamanho da fonte do título das colunas
            },
          }}
        />
        {/* {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>

      {/* <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>{newDialog ? "Novo" : "Editar"} Ativo</DialogTitle>
        <DialogContent>
          {currentRow && (
            <>
              <Grid
                container
                direction={'column'}
                style={{ marginBottom: "0px" }}
              >
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "10px",
                      minWidth: 550,
                    }}
                  >
                    <TextField
                      margin="dense"
                      name="title"
                      label="Ativo"
                      type="text"
                      fullWidth
                      disabled={!newDialog}
                      value={currentRow.title}
                      error={!currentRow.title}
                      // {...register("title", {
                      //   required: "Campo Obrigatório",
                      // })}
                      onChange={(e) => handleChange(e, "title")}
                    />
                    {!currentRow.title && (
                      <FormHelperText error={!currentRow.title}>Título é obrigatório</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      minWidth: 300,
                    }}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Bandeira *
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // {...register("retailer", {
                      //   required: "*",
                      // })}
                      disabled={!newDialog}
                      value={currentRow.retailer}
                      error={!currentRow.retailer}
                      defaultValue={""}
                      onChange={(e) => handleChangeRetailer(e)}
                      input={<OutlinedInput label="Bandeira *" />}
                    >
                      {vet_bandeiras.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    {!currentRow.retailer && (
                      <FormHelperText error={!currentRow.retailer}>Bandeira é obrigatória</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      minWidth: 250,
                    }}
                    error={!!errors.title}
                  >
                    <InputLabel id="type-label">Grupo *</InputLabel>
                    <Select
                      labelId="type-label"
                      id="type-cmb"
                      // {...register("type", {
                      //   required: "*",
                      // })}
                      disabled={!newDialog}
                      value={currentRow.group}
                      defaultValue={""}
                      onChange={(e) => handleChangeType(e)}
                      input={<OutlinedInput label="Grupo *" />}
                      error={!currentRow.group}
                    >
                      {typeList.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.group}
                        </MenuItem>
                      ))}
                    </Select>

                    {!currentRow.group && (
                      <FormHelperText error={!currentRow.group}>Grupo é obrigatório</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {!newDialog && <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "10px",
                      marginBottom: "5px",
                      minWidth: 120,
                    }}
                    error={!!errors.base_price}
                  >
                    <CurrencyTextField
                      name="base_price"
                      label={'Preço Base'}
                      fullWidth
                      disabled={!newDialog}
                      value={currentRow.base_price}
                    />
                  </FormControl>
                </Grid>}

              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          {(newDialog && hasFullPermission(user)) && <Button onClick={handleSubmit(handleSave)} color="primary">
            Salvar
          </Button>}
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
};

export default Assets;
