import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { onAuthStateChanged } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from '../../../AuthContext';

import CurrencyTextField from "../../../components/CurrencyTextField";

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import { Table, TableBody, TableCell, TableRow, TableContainer, Paper, Collapse } from '@mui/material';

import {
  BANDEIRAS,
  REGIOES,
  FIREBASE_CONFIG,
  formatBandeira,
  formatCurrency,
  hasFullPermission,
  formatDecimal
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Box from "@mui/material/Box";
import SimpleGrid from "../../../components/SimpleGrid";

const vet_bandeiras = BANDEIRAS;

const Store = ({ firebaseApp }) => {
  const theme = useTheme();

  const { user, name } = useAuth()

  const navigate = useNavigate();

  const [newDialog, setNewDialog] = useState(false);

  const [searchResults, setSearchResults] = useState([]);
  const [rows, setRows] = useState([]);
  const [assets, setAssets] = useState([]);
  const [selectedStore, setSelectedStore] = useState();

  const [filter, setFilter] = useState("");
  const [regiao, setRegiao] = useState("Todas");
  const [retailer, setRetailer] = useState("Todas");

  const [regionsFiltro, setRegionsFiltro] = useState([]);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [expandedRowId, setExpandedRowId] = useState(null);


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/store-all`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
          setRows(response.data);
          callFilter(response.data)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const handleSave = async () => {
    if (newDialog) {
      
    } else {
      setLoading(true);

      let formData = {
        ...currentRow,
        last_user_update: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .put(`${apiUrl}/api/v1/store/${currentRow.id}`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            toast.success('Loja atualizada com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };


  /* ************************************* */

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const fetchAssets = async (id) => {
    console.log("Loading...");

    // setLoading(true);

    try {

      const response = await axios.get(`${apiUrl}/api/v1/store-asset/${id}`, {});

      const responseData = response.data;

      return responseData
      
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const handleRowExpand = async (row) => {

    let rowId = row.id

    setSelectedStore(`${row.id} - ${row.name}`);

    try {
      const response = await fetchAssets(rowId);
      setAssets(response);

      setOpen(true)

    } catch (error) {
      console.error('Erro ao carregar os ativos:', error);
    }

  };

  const renderSubRows = (assets) => {

    if(!assets || assets?.length === 0) {
      return []
    }
    else {
      return assets.map((asset, index) => ({
        ...asset,
        index: index, 
        id: `subrow-${asset.id}`,
        parentId: asset.store_id 
      }));
    }
 
  }

  /* ************************************* */

  const handleChangeBandeira = (e) => {
    const { name, value } = e.target;
    setRetailer(value);
  };

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;
    setRegiao(value);
  };

  const handleChange = (event, _id) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, [_id]: value }));
  };

  const handleEditClick = (row) => {
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentRow(null);
  };

  const handleInputNumChange = (values) => {
    const { value } = values;
    setCurrentRow((prevRow) => ({ ...prevRow, 'gmv': value }));
  };

  const callFilter = (_stores = []) => {

    let data = searchResults.length === 0 ? _stores : searchResults

    // Filtro inline aplicado ao array de rows
    const filteredRows = data.filter(
      (row) =>
        (filter === "" ||
          row.id.toLowerCase().includes(filter.toLowerCase()) ||
          row.name.toLowerCase().includes(filter.toLowerCase())) &&
        (regiao === "Todas" ||
          row.region.toUpperCase() === regiao ||
          row.state.toUpperCase() === regiao) &&
        (retailer === "Todas" ||
          row.retailer.toString().toUpperCase() === retailer.toUpperCase())
    );

    const uniqueRegions = [...new Set(data.map(item => item.region))];
    let regions_filtro = REGIOES.filter((el) => uniqueRegions.includes(el.id))
    setRegionsFiltro(regions_filtro)

    setRows(filteredRows.sort((a, b) => {
      return (b.name === a.name) ? 0 : b.name ? 1 : -1;
    }))
  }

  const columnsPopup = [
    {
      field: "title",
      headerName: "Ativo",
      flex: 1
    },
    {
      field: "qty",
      headerName: "Qtd cadastrada",
      width: 150,
      type: 'number',
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),

    },
  ]

  const columns = [
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 80,
      renderCell: (params) => params.value && formatBandeira(params.value),

    },
    {
      field: "id",
      headerName: "Loja",
      flex: 1,
      renderCell: (params) => (
        <>
          {params.row.id} - {params.row.name}
        </>
      ),
    },
    {
      field: "last_gmv",
      headerName: "Último GMV",
      width: 120,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),

    },
    {
      field: "total_store_price",
      headerName: "Total Valor Loja",
      width: 120,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),
    },
    {
      field: "diff",
      headerName: "Diferença %",
      width: 100,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 2).replace(',', '.')}
        </>
      ),
    },
    {
      field: "total_groups",
      headerName: "Grupos de Ativos",
      width: 120,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),
    },
    {
      field: "total_unique_assets",
      headerName: "Ativos Únicos",
      width: 120,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),
    },
    {
      field: "total_assets",
      headerName: "Total Ativos",
      width: 120,
      type: 'number',
      headerClassName: "multi-line-header",
      renderCell: (params) => (
        <>
          {formatDecimal(params.value, 0)}
        </>
      ),
    },
    {
      field: "state",
      headerName: "UF",
      width: 60
    },
    {
      field: "region",
      headerName: "Região",
      width: 60
    }
    // {
    //   field: "actions",
    //   headerName: "Ações",
    //   width: 100,
    //   renderCell: (params) => (
    //     <>
    //       <IconButton
    //         aria-label="edit"
    //         color="primary"
    //         onClick={() => handleEditClick(params.row)}
    //       >
    //         <EditIcon />
    //       </IconButton>
    //       {/* <IconButton aria-label="delete" color="primary" disabled>
    //         <DeleteIcon />
    //       </IconButton> */}
    //     </>
    //   ),
    // },
  ];

  useEffect(() => {
    callFilter()
  }, [filter,regiao,retailer]);

  return (
    <React.Fragment>
      <h2>Lojas</h2>
      <div style={{fontSize: '12px', marginTop: '-10px', marginBottom: '10px'}}>Clique para ver os ativos</div>

      <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-start', alignItems: "center" }}>
        <div style={{ width: '300px' }}>
          <FormControl fullWidth>
            <InputLabel id="lblRetailer">Bandeira</InputLabel>
            <Select
              fullWidth
              value={retailer}
              labelId="lblRetailer"
              id="cmbRetailer"
              name="retailer"
              label="Bandeira"
              onChange={handleChangeBandeira}
            >
              <MenuItem key="all" value="Todas">Todas</MenuItem>

              {BANDEIRAS.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: '300px' }}>
          <FormControl fullWidth>
            <InputLabel id="lblRegiao">Região</InputLabel>
            <Select
              fullWidth
              value={regiao}
              labelId="lblRegiao"
              id="cmbRegiao"
              name="regiao"
              label="Região"
              onChange={handleChangeSelect}
            >
              <MenuItem key="all" value="Todas">Todas</MenuItem>

              {regionsFiltro.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.regiao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ marginBottom: "4px" }}>
            <TextField
              margin="dense"
              name="id"
              label="Busca"
              type="text"
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
        </div>
      </div>
      
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          onRowClick={(params) => handleRowExpand(params.row)}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          rowHeight={35} 
          sx={{
            fontSize: '0.7rem',
            '& .MuiDataGrid-cell': {
              padding: '2px 5px',
            },
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
            '& .MuiDataGrid-columnHeaders': {
              minHeight: '40px !important',
              lineHeight: '40px !important',
              maxHeight: '40px !important',
              height: '40px !important'
            },
            '& .MuiDataGrid-columnHeader': {
              padding: '0px 5px',
              margin: '0px',
              height: '40px !important'
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontSize: '0.75rem', // Diminui o tamanho da fonte do título das colunas
            },
          }}
        />
        
        {/* 
        loading fullscreen
        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {selectedStore} - {assets.length} Ativo(s) nesta loja
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 1,
              height: "calc(100vh - 450px)",
              overflowY: "scroll",
              overflowX: "hidden",
            }}
          >
            <DataGrid
              rows={assets}
              columns={columnsPopup}
              loading={loading}
              autoHeight
              localeText={{
                noRowsLabel: "Nada encontrado",
              }}
              getRowId={(row) => row.id}
              rowHeight={30}
              sx={{
                ".MuiDataGrid-overlayWrap": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapperInner": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
                ".MuiDataGrid-overlayWrapper": {
                  height: "55px !important",
                  fontWeight: "bold",
                },
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

export default Store;
