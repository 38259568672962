import React, { createContext, useContext, useState , useEffect} from 'react';

import toast, { Toaster } from "react-hot-toast";

import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [name, setName] = useState('');

    const history = useNavigate();

    const [loading, setLoading] = useState(true); 

    // useEffect(() => {
    //     const refreshToken = async () => {

    //         await instance.initialize();

    //         const request = {
    //             ...loginRequest,
    //             account: accounts[0],
    //         };

    //         await instance
    //             .acquireTokenSilent(request)
    //             .then((response) => {
    //                 console.log("refreshToken", response);

    //                 loginSuccess(
    //                     response.account.name,
    //                     response.account.username,
    //                     response.accessToken
    //                 );
    //             })
    //             .catch((e) => {
    //                 instance
    //                     .acquireTokenPopup(request)
    //                     .then((response) => {
    //                         setAccessToken(response.accessToken);
    //                     })
    //                     .catch((e) => {
    //                         console.error(e);
    //                     });
    //             });
    //     };

    //     refreshToken();
    // }, []);

    // const login = (userData) => {
    //     setUser(userData);
    //     // Opcional: você pode armazenar os dados no localStorage para persistência
    //     localStorage.setItem('user', JSON.stringify(userData));
    // };

    const login = (user, name) => {
        setUser(user)
        setName(name)

        localStorage.setItem("user", user);
        localStorage.setItem("name", name);
    };

    const logout = async () => {
        setUser(null);  
        setName(''); 
        localStorage.removeItem('user');
        localStorage.removeItem('name');
    };

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(storedUser);
        }

        const storedName = localStorage.getItem('name');
        if (storedName) {
            setName(storedName);
        }

        setLoading(false);

    }, []);

    // const signInWithFirebase = async (customToken) => {
    //     // signInAnonymously(auth)
    //     //     .then((userCredential) => {
    //     //         // console.log("Usuário autenticado no Firebase:", userCredential.user);
    //     //         console.log("Usuário autenticado no Firebase...");
    //     //     })
    //     //     .catch((error) => {
    //     //         console.error("Erro na autenticação do Firebase:", error.toString());
    //     //     });
    // };

    return (
        <AuthContext.Provider value={{ user, name, loading, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
