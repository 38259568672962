import React, { useContext, useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import FaceIcon from "@mui/icons-material/Face";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router-dom";

import { hasAdminPermission } from "../../../router/store/types";
import toast from "react-hot-toast";

import { useAuth } from "../../../AuthContext";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Elemento de ancoragem

  const { logout, user, name } = useAuth();

  const handleLogout = () => {
    localStorage.setItem("user", "");
    localStorage.setItem("name", "");

    toast.success("Sessão encerrada");

    logout();

    navigate("/");
  };

  // Função para alternar a visibilidade do menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  // Função para fechar o menu ao clicar fora
  const handleClickOutside = (event) => {
    if (
      !event.target.closest("#small-menu") &&
      !event.target.closest("#button-menu")
    ) {
      setOpen(false);
      setAnchorEl(null);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {user && (
        <>
          <div style={{ position: "relative" }}>
            {/* Botão que abre o menu */}
            <button id="button-menu" onClick={handleClick}>
              <span className="menu-icon">☰</span>
            </button>

            {/* Menu suspenso controlado pelo estado */}
            {open && (
              <div
                id="small-menu"
                className="dropdown-menu"
                style={{
                  top: anchorEl?.getBoundingClientRect().top + 28,
                  right: anchorEl?.getBoundingClientRect().width - 60, // Posiciona à esquerda do botão
                  left: "auto", // Define como 'auto' para permitir que o menu flua corretamente
                }}
              >
                <a href="/proposals">Emissão de Proposta</a>
                <a href="/stores">Lojas</a>
                <a href="/assets">Ativos</a>
                <a href="/asset-types">Grupos de Ativos</a>
                <a href="/asset-costs">Tabelas de Custos</a>
                <a href="/assets/pricing">Precificação de Ativos</a>
                <a href="/industries">Indústrias</a>
                {hasAdminPermission(user) && <a href="/tools/loader">Loader</a>}
                <a href="#" onClick={() => handleLogout()}>
                  Sair
                </a>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Header;
