import * as React from 'react';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { useForm } from "react-hook-form";


export default function DecimalTextField({ label, value, onChange = (e) => { }, name, decimalScale, required, 
error, inputProps, InputLabelProps, size, variant, disabled,
MIN_LIMIT = 0, MAX_LIMIT = 1000 }) {
    
    const {
        register,
    } = useForm();

    return (
        <NumericFormat
            customInput={TextField}
            thousandSeparator="."
            decimalSeparator=","
            prefix=""
            decimalScale={decimalScale}
            fixedDecimalScale
            onValueChange={onChange}
            label={label}
            fullWidth
            value={value}
            error={error}
            name={name}
            required={required}
            inputProps={inputProps}
            InputLabelProps={InputLabelProps}
            size={size}
            variant={variant}
            disabled={disabled}
            isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue >= MIN_LIMIT && floatValue <= MAX_LIMIT;
            }}
        />
    );
}
