import React from "react";
import "./SimpleGrid.css";

function SimpleGrid({ rows, columns }) {
  {
    console.log("rows", rows, columns);
  }

  return (
    <div className="datagrid">
      <div className="headers">
        {columns.map((header) => (
          <div key={header?.field} style={{width: `${header.width}px`}} className="header">
            {header?.headerName}
          </div>
        ))}
      </div>
      <div className="rows">
        {rows.map((row, rowIndex) =>
          {
            return <div className="row">
              {columns.map((header) => (
                <div key={`${rowIndex}-${header?.field}`}  style={{width: `${header.width}px`}}  className="cell">
                  {row[header?.field]}
                </div>
              ))}
            </div>
          }
        )}
      </div>
    </div>
  );
}

export default SimpleGrid;
