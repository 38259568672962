import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Papa from "papaparse";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { Button } from "@mui/material";

import { FormControl } from "@mui/material";
import { getEstadosPorRegiao } from "../../../../router/store/types";

function Loader() {
  const [arquivo, setArquivo] = useState("");
  const [fileData, setFileData] = useState();
  const [loading, setLoading] = useState(false);

  const [_console, setConsole] = useState("");

  const handleFileUpload = (event) => {
    // const file = event.target.files[0];
    // Papa.parse(file, {
    //   header: true,
    //   complete: (results) => {
    //     setCsvData(results.data);
    //   },
    // });

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const json = JSON.parse(e.target.result);
        setFileData(json)
      } catch (error) {
        console.error("Invalid JSON file");
      }
    };

    reader.readAsText(file);
  };

  const uploadData = async () => {
    if (arquivo === "ativos") {
      loadAssets();
    } else if (arquivo === "ativos-store") {
      loadAssetsByStore();
    } else if (arquivo === "industria") {
      loadIndustry();
    } else if (arquivo === "lojas") {
      loadStores();
    }
  };

  const loadAssetsByStore = async () => {
    setLoading(true);
    try {

      /*
      {
    "sucesso": "Sucesso",
    "mensagem": "Consulta de inventario consultada com sucesso!",
    "data": [
        {
            "inventoryId": 687341,
            "quantity": 500,
            "assetId": 16322,
            "name": "SAMS-Placa de carrinho",
            "storeId": 6599
        },

        {"inventoryId":632139,"quantity":20,"name":"Wobbler Aniversário","assetId":4194,"storeId":5413,"isAvaliable":true,"isActive":true}
      */

      const filteredData = fileData.data.map(({ inventoryId, quantity, assetId, name, storeId }) => ({
        store_id: storeId,
        asset_id: assetId,
        qty: quantity,
        inventoryIdTegra: inventoryId,
        name: name.trim().replace("\"", "")
      })).filter(el => el.qty > 0);

      console.log(filteredData)

      // convertendo em CSV
      const csv = Papa.unparse(filteredData);

      setConsole(csv)

      toast.success("Dados carregados com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const loadAssets = async () => {
    setLoading(true);
    try {

      // const filteredData = fileData.map(({ InventarioItemId, Nome, Ativo }) => ({
      //   AssetId: InventarioItemId,
      //   Title: Nome.toUpperCase(),
      //   Ativo: (Ativo) ? 1 : 0
      // }));

      const filteredData = fileData.data.map(({ assetId, name }) => ({
        AssetId: assetId,
        Title: name.toUpperCase().trim().replace("\"", "")
      }));

      // Remove duplicatas de AssetId usando um Map
      const uniqueData = Array.from(
        new Map(filteredData.map(item => [item.AssetId, item])).values()
      ).sort((a,b) => a.Title.toString().localeCompare(b.Title));

      console.log(uniqueData)

      // convertendo em CSV
      const csv = Papa.unparse(uniqueData);

      setConsole(csv)

      toast.success("Dados carregados com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  const loadIndustry = async () => {
    // setLoading(true);
    // try {
    //   const data = await getDocs(collection(db, "industries"));

    //   for (const row of csvData) {
    //     const { id, acronym, last_proposal } = row;
    //     if (id !== "") {
    //       await setDoc(doc(db, "industries", id.replace("/", "-")), {
    //         acronym: acronym.replace("/", "-"),
    //         last_proposal: 0,
    //       });

    //       console.log("Documento escrito com ID: ", id);
    //     }
    //   }
    //   alert("Dados carregados com sucesso!");
    // } catch (error) {
    //   console.error("Erro ao adicionar documento: ", error.toString());
    //   alert("Erro ao carregar dados.");
    // } finally {
    //   setLoading(false);
    // }
  };

  const loadStores = async () => {
    setLoading(true);
    try {
      
      const filteredData = fileData.map(({ LojaId, Nome, Sigla, Bandeira, UF, Ativo, FormatoLoja }) => ({
        LojaId,
        Nome: Nome.toUpperCase(),
        Sigla: Sigla.toUpperCase(),
        Bandeira: Bandeira?.Nome,
        UF: UF.toUpperCase(),
        Ativo: (Ativo) ? 1 : 0,
        Regiao: getEstadosPorRegiao(UF.toUpperCase()),
        Formato: FormatoLoja.Nome
      }));

      console.log(filteredData)

      // convertendo em CSV
      const csv = Papa.unparse(filteredData);

      setConsole(csv)

      toast.success("Dados carregados com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar documento: ", error.toString());
      alert("Erro ao carregar dados.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <FormControl fullWidth style={{ marginTop: "10px", width: "300px" }}>
        <InputLabel id="demo-multiple-name-label">Arquivo *</InputLabel>
        <Select
          required
          value={arquivo}
          name="retailer"
          onChange={(e) => setArquivo(e.target.value)}
          input={<OutlinedInput label="Arquivo" />}
        >
          <MenuItem key={"lojas"} value={"lojas"}>
            Lojas
          </MenuItem>
          <MenuItem key={"industria"} value={"industria"}>
            Indústria
          </MenuItem>
          <MenuItem key={"ativos"} value={"ativos"}>
            Cadastro Ativos
          </MenuItem>
          <MenuItem key={"ativos-store"} value={"ativos-store"}>
            Ativos Por Loja
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px"}}>
        <input
          style={{ fontSize: "14px", width: "300px" }}
          type="file"
          accept=".csv,.json"
          onChange={handleFileUpload}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <Button
          variant="contained"
          color="secondary"
          type="submit"
          size="large"
          style={{ width: "150px", marginRight: "10px" }}
          onClick={uploadData}
          disabled={loading}
        >
          {loading ? "Carregando..." : "Upload"}
        </Button>
      </FormControl>

      <FormControl fullWidth sx={{ padding: 2, width: '100%', height: "calc(100vh - 330px)", overflowY: "scroll", overflowX: "hidden" }}>

        <textarea style={{ width: '100%', height: '100%', fontFamily: 'monospace' }} value={_console} /> 
        
      </FormControl>

    </div>
  );
}

export default Loader;
