import React, { useState, useEffect } from "react";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";

import CurrencyTextField from "../../../components/CurrencyTextField";

import { useAuth } from '../../../AuthContext';

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import {
  TIPOS_ATIVOS,
  ESTADOS,
  hasFullPermission,
  formatBandeira
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const vet_estados = ESTADOS;

const AssetCosts = () => {
  const theme = useTheme();

  const { user, name } = useAuth()

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);
  const [rows, setRows] = useState([]);

  const [loading, setLoading] = useState(true);

  const [filter, setFilter] = useState("");

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [retailerError, setRetailerError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleEditClick = (row) => {
    
    navigate(`/asset-costs/${row.id}`);
    
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      width: 100
    },
    {
      field: "table_name",
      headerName: "Tabela",
      flex: 1
    },
    {
      field: "actions",
      headerName: "Ações",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => showConfirmation(params.row.id, params.row.title)}
          >
            <DeleteIcon />
          </IconButton> */}
        </>
      ),
    },
  ];

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-cost`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
          setRows(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
    };

    fetch();
  }, []);

  const callFilter = () => {

    // Filtro inline aplicado ao array de rows
    const filteredRows = searchResults.filter(
      (row) =>
      (filter === "" ||
        row.table_name.toLowerCase().includes(filter.toLowerCase()))
    );

    setRows(filteredRows.sort((a, b) => {
      return (b.title === a.title) ? 0 : b.title ? 1 : -1;
    }))
  }

  useEffect(() => {
    callFilter()
  }, [filter]);

  return (
    <React.Fragment>
      <h2>Tabelas de Custos de Ativos</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "50px",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        <div style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <TextField
              margin="dense"
              name="id"
              label="Busca"
              type="text"
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
        </div>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "200px" }}
          startIcon={<AddIcon />}
          onClick={() => {
            navigate("/asset-costs/new");
          }}
        >
          Novo
        </Button>
      </div>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 50]}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          loading={loading}
          rowHeight={35}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
      </Box>

    </React.Fragment>
  );
};

export default AssetCosts;
