import React, { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import { ptBR } from "@mui/x-date-pickers/locales";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  CircularProgress,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DoorBackIcon from "@mui/icons-material/DoorBack";

import {
  BANDEIRAS,
  REGIOES,
  formatDate,
  getEstadosPorRegiao,
} from "../../../../router/store/types";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import ProposalAssetGrid from "../../../../components/ProposalAssetGrid";
import ProposalStoreGrid from "../../../../components/ProposalStoreGrid";

import { useAuth } from "../../../../AuthContext";
import ProposalSummary from "../ProposalSummary";

import { useNavigate, useLocation } from "react-router-dom";
import ProposalPdf from "../ProposalPdf";
import e from "cors";

const steps = [
  "Abertura",
  "Selecione os Ativos",
  "Selecione as Lojas",
  "Resumo da Proposta",
  "Gerar Documento",
];

const brazilLocale =
  ptBR.components.MuiLocalizationProvider.defaultProps.localeText;

function ConfirmDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Atenção</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A proposta está revisada?
          <br />
          <br />
          <i>
            Esta versão não está permitindo edição de proposta. <br />
            Confira com atenção!
          </i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function ProposalForm() {
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState({
    client_id: "",
    client: "",
    clientContact: "",
    campaign: "",
    start_date: dayjs(),
    end_date: dayjs(),
    inc_user_id: 0,
    inc_date: "",
    comments: "",
    retailer: "",
  });

  const [edit, setEdit] = useState(false);

  const [open, setOpen] = useState(false);

  const [retailer, setRetailer] = useState("");
  const [assets, setAssets] = useState([]);
  const [stores, setStores] = useState([]);

  const [proposalId, setProposalId] = useState();
  const [proposal, setProposal] = useState();

  const [clients, setClients] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { user, name } = useAuth();

  const [loading, setLoading] = useState(false);

  // Dialog
  const openConfirm = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = async () => {
    await handleSubmit();

    setOpen(false);
  };

  //

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchDataStep1 = async () => {
    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/industry`, {})
        .then((response) => {
          //if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setClients(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setClients([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchDataStep1();
    };
    fetch();
  }, []);

  const getDataStep2 = (assets) => {
    // console.log('callback', assets)
    setAssets(assets);
  };

  const getDataStep3 = (updatedStores, redirectToAssets = false) => {
    setStores(updatedStores);
  };

  const getProposalData = (proposal) => {
    console.log("callback", proposal);
    setProposal(proposal);
  };

  const getStepContent = (stepIndex, values, handleChange) => {
    switch (stepIndex) {
      case 0:
        return (
          <Box sx={{ marginTop: "10px" }}>
            <FormControl fullWidth sx={{ marginBottom: "10px" }}>
              <InputLabel id="lblBandeira" size="small">Bandeira</InputLabel>
              <Select
                labelId="lblBandeira"
                id="cmbBandeira"
                value={values.retailer}
                label="Bandeira*"
                onChange={handleChange("retailer")}
                error={!values.retailer}
                size="small"
              >
                {BANDEIRAS.sort((a, b) => a.localeCompare(b)).map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
              {!values.retailer && (
                <FormHelperText error={!values.retailer}>
                  Bandeira é obrigatória
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth sx={{ marginBottom: "0px" }}>
              <InputLabel id="lblClient" size="small">Cliente</InputLabel>
              <Select
                labelId="lblClient"
                id="cmbClient"
                value={values.client_id}
                label="Cliente*"
                onChange={handleChange("client_id")}
                error={!values.client_id}
                size="small"
              >
                {clients.map((el) => (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
              {!values.client_id && (
                <FormHelperText error={!values.client_id}>
                  Cliente é obrigatório
                </FormHelperText>
              )}
            </FormControl>

            <TextField
              label="Contato do Cliente"
              value={values.clientContact}
              onChange={handleChange("clientContact")}
              fullWidth
              margin="normal"
              error={!values.clientContact}
              helperText={
                !values.clientContact && "Contato do Cliente é obrigatório"
              }
              size="small"
              sx={{marginBottom: '0px'}}
            />

            <TextField
              label="Campanha"
              value={values.campaign}
              onChange={handleChange("campaign")}
              fullWidth
              margin="normal"
              error={!values.campaign}
              helperText={!values.campaign && "Campanha é obrigatória"}
              size="small"
              sx={{marginBottom: '0px'}}
            />

            {values.user && (
              <TextField
                label="Sales Manager"
                fullWidth
                margin="normal"
                sx={{ width: "calc(100vh - 230px)", marginBottom: '0px' }}
                defaultValue={values.user}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
              />
            )}

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="ptBR"
              localeText={brazilLocale}
            >
              <DatePicker
                label="Início"
                value={values.start_date}
                onChange={(newValue) =>
                  handleChangeDate(newValue, "start_date")
                }
                sx={{ width: "250px", marginTop: "20px" }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    error: Boolean(!values.start_date),
                    helperText:
                      !values.start_date && "Data de Início é obrigatória",
                  },
                }}
                size="small"
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="ptBR"
              localeText={brazilLocale}
            >
              <DatePicker
                label="Fim"
                value={values.end_date}
                onChange={(newValue) => handleChangeDate(newValue, "end_date")}
                sx={{ width: "250px", marginLeft: "20px", marginTop: "20px" }}
                format="DD/MM/YYYY"
                slotProps={{
                  textField: {
                    error: Boolean(!values.end_date),
                    helperText: !values.end_date && "Data Final é obrigatória",
                  },
                }}
                size="small"
              />
            </LocalizationProvider>

            {values.inc_date && (
              <TextField
                label="Data da Proposta"
                margin="normal"
                sx={{ width: "250px", marginLeft: "20px" }}
                defaultValue={values.inc_date}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
              />
            )}

            <TextField
              label="Observações"
              variant="outlined"
              color="secondary"
              name={"comments"}
              value={values.comments}
              multiline
              rows={3}
              onChange={handleChange("comments")}
              fullWidth
              margin="normal"
              size="small"
            />
          </Box>
        );

      case 1:
        return (
          <ProposalAssetGrid
            header={values}
            rows={assets}
            callback={getDataStep2}
            edit={edit}
          />
        );

      case 2:
        return (
          <ProposalStoreGrid
            header={values}
            rows={stores}
            assets={assets}
            callback={getDataStep3}
            edit={edit}
          />
        );

      case 3:
        return (
          <ProposalSummary
            data={{ proposal: values, stores: stores, assets: assets }}
            callback={getProposalData}
            edit={edit}
          />
        );

      case 4:
        return (
          <ProposalPdf
            data={{ proposal: proposal.proposal, assets: proposal.pricing }}
            edit={edit}
          />
        );

      default:
        return "🛑 Erro! Recarregue a tela.";
    }
  };

  const handleChangeDate = (value, prop) => {
    let _values = {};
    // const formattedDate = format(new Date(value), 'dd/MM/yyyy');
    _values = { ...values, [prop]: value };
    setValues(_values);
  };

  const handleChange = (prop) => (event) => {
    let _values = {};
    if (prop === "client_id") {
      const _name = clients.find((el) => el.id === event.target.value).name;
      _values = { ...values, client_id: event.target.value, client: _name };
    } else {
      if (prop === "retailer") {
        setAssets([]);
      }

      _values = { ...values, [prop]: event.target.value };
    }

    setValues(_values);
  };

  const redirectToStart = () => {
    navigate("/proposals");
  };

  const handleNext = async () => {
    const valid = await validateStep();
    if (valid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validateStep = async () => {
    let result = false;
    switch (activeStep) {
      case 0:
        result =
          values.retailer &&
          values.client_id &&
          values.clientContact &&
          values.campaign &&
          values.start_date &&
          values.end_date;
        if (!result) {
          toast.error("Dados da Proposta não encontrados");
        }
        return result;

      case 1:
        result = assets.length > 0;
        if (!result) {
          toast.error("Ativos não foram selecionados");
          return result;
        }

        result = assets.every((el) => el.qty_proposta > 0);
        if (!result) {
          toast.error("As quantidades de cada Ativo não foram informadas");
          return result;
        }

        result = assets.every((el) => el.qty_proposta <= el.qty_max);
        if (!result) {
          toast.error(
            `A quantidade de um ou mais Ativos é superior à quantidade máxima`
          );
          return result;
        }

        setStores([]);

        return result;

      case 2:
        result = stores.length > 0;
        if (!result) {
          toast.error("Lojas não foram selecionadas");
          return result;
        }

        const selectedStores = stores.filter((store) => store.checked);

        result = selectedStores.length > 0;
        if (!result) {
          toast.error("Nenhuma loja selecionada");
          return false;
        }

        const hasIssues = selectedStores.some((store) => store.status !== 1);

        if (hasIssues) {
          toast.error(
            "Por favor, resolva os problemas nas lojas sinalizadas antes de prosseguir."
          );
          return false;
        }

        return true;

      case 3:
        result = values.client_id && values.clientContact && values.campaign;

        if (!result) {
          toast.error("Dados do Cliente não encontrados");
        }
        return result;

      case 4:
        result = values.proposal_id;
        if (!result) {
          toast.error("A Proposta não foi salva ou ocorreu um erro");
        }
        return result;

      default:
        return false;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const formattedDate = new Date()
        .toLocaleString("sv-SE", {
          timeZone: "America/Sao_Paulo",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(",", "");

      let _values = values;

      _values = {
        ..._values,
        inc_user_id: `${name} <${user}>`,
        inc_date: formattedDate,
      };
      setValues(_values);

      proposal.proposal = {
        ...proposal.proposal,
        start_date: dayjs(proposal.proposal.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(proposal.proposal.end_date).format("YYYY-MM-DD"),
        inc_user_id: `${name} <${user}>`,
        inc_date: formattedDate,
        client_contact: proposal.proposal.clientContact,
      };

      proposal.pricing = proposal.pricing.map((el) => ({
        ...el,
        start_date: dayjs(el.start_date).format("YYYY-MM-DD"),
        end_date: dayjs(el.end_date).format("YYYY-MM-DD"),
      }));

      let formData = {
        proposal: proposal.proposal,
        assets: proposal.pricing,
      };

      console.log("Formulário enviado:", formData);

      axios
        .post(`${apiUrl}/api/v1/proposal`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 201) {
            values.proposal_id = response.data.proposal_id;

            setProposalId(values.proposal_id);
            proposal.proposal.proposal_id = values.proposal_id;

            setValues({ ..._values, proposal_id: values.proposal_id });

            toast.success("Proposta incluída com sucesso!");
            handleNext();
          }
        })
        .catch((error) => {
          console.error("Erro ao salvar os dados:", error);
          toast.error("Erro ao salvar os dados.");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%", marginTop: "10px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "250px" }}>
            <h2>Nova Proposta</h2>
          </div>
          <div style={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                "& .MuiStepIcon-root": {
                  "&.Mui-active": {},
                  "&.Mui-completed": {
                    color: "green", // Cor do ícone dos passos completados
                  },
                },
                "& .MuiStepLabel-label": {
                  fontSize: "10px", // Tamanho da fonte do texto do label
                },
                "& .MuiStepConnector-root": {
                  height: 3, // Espessura da linha entre os passos
                  color: "grey", // Cor da linha
                },
              }}
            >
              {steps.map((label, i) => (
                <Step key={label}>
                  <StepLabel>
                    {label}
                    <br />
                    {activeStep > 0 && i === 1
                      ? `(${assets.length})`
                      : activeStep > 1 && i === 2
                      ? `(${stores.length})`
                      : ""}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

        <Box sx={{ padding: 2 }}>
          {getStepContent(activeStep, values, handleChange)}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 2,
            }}
          >
            <Button
              sx={{ marginRight: 1 }}
              onClick={redirectToStart}
              color="error"
              variant="contained"
            >
              Sair <DoorBackIcon sx={{ marginLeft: "5px" }} />
            </Button>

            <div>
              <Button
                onClick={handleBack}
                sx={{ marginRight: 1 }}
                disabled={activeStep === 0 || activeStep === 4}
                color="secondary"
                variant="contained"
              >
                Voltar
              </Button>

              {activeStep === 3 ? (
                <Button
                  onClick={openConfirm}
                  color="primary"
                  variant="contained"
                >
                  De Acordo <TaskAltIcon sx={{ marginLeft: "5px" }} />
                </Button>
              ) : (
                <Button
                  onClick={handleNext}
                  color="primary"
                  variant="contained"
                  disabled={activeStep === 4}
                >
                  Próximo <NavigateNextIcon sx={{ marginLeft: "5px" }} />
                </Button>
              )}
            </div>
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      <ConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </>
  );
}
