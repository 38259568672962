import React, { useContext, useState } from "react";

import {useAuth} from '../../../AuthContext';

const Home = () => {

  const { user, name } = useAuth();

  const divStyle = {
    width: "100%",
    height: "600px",
    backgroundImage: 'url("/wallpaper-unlimitail.png")',
    backgroundSize: "contain", // Ajusta a imagem para cobrir toda a área da div
    backgroundPosition: "center", // Centraliza a imagem na div
    backgroundRepeat: "no-repeat", // Evita repetição da imagem
  };

  return (
    <>
      <div style={divStyle}>
        <h2>Olá, {name}!</h2>

        <br />
        <br />
      </div>
    </>
  );
};

export default Home;
