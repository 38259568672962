import React, { useState, useEffect } from "react";
import axios from "axios";

import toast, { Toaster } from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";

import CurrencyTextField from "../../../components/CurrencyTextField";

import { useAuth } from '../../../AuthContext';

import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";

import {
  BANDEIRAS,
  TIPOS_ATIVOS,
  formatBandeira,
  formatCurrency,
  hasFullPermission
} from "../../../router/store/types";

import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const vet_tipos = TIPOS_ATIVOS;

const AssetTypes = () => {
  const theme = useTheme();

  const { user, name } = useAuth()

  const navigate = useNavigate();

  const [searchResults, setSearchResults] = useState([]);
  const [assetCostList, setAssetCostList] = useState([]);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState("");
  const [retailer, setRetailer] = useState("Todas");

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [newDialog, setNewDialog] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);

  const [retailerError, setRetailerError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleNewClick = () => {
    let row = {
      id: "",
      group: "",
      type: "",
      qty_min: 1,
      qty_max: 2,
      multiplier: 1
    };

    setNewDialog(true);
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const handleEditClick = (row) => {
    setCurrentRow({ ...row });
    setOpen(true);
  };

  const fetchAssetCostTables = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-cost`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setAssetCostList(
            response.data.sort((a, b) => a.table_name.localeCompare(b.table_name))
          );
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setAssetCostList([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setNewDialog(false);
    setCurrentRow(null);
    reset();
  };

  const handleSave = async () => {
    if (newDialog) {
      setLoading(true);

      let formData = {
        ...currentRow,
        last_user_update: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .post(`${apiUrl}/api/v1/asset-type`, formData)
        .then(async (response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 201) {
            toast.success('Tipo de Ativo incluído com sucesso!');
            await fetchAll()
            await callFilter()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
      
    } else {
      setLoading(true);

      let formData = {
        ...currentRow,
        last_user_update: `${name} <${user}>`
      }

      console.log("Formulário enviado:", formData);

      axios
        .put(`${apiUrl}/api/v1/asset-type/${currentRow.id}`, formData)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);

          if (response.status === 200) {
            toast.success('Tipo de Ativo atualizado com sucesso!');
            fetchAll()
          }
        })
        .catch((error) => {
          console.error('Erro ao salvar os dados:', error);
          toast.error('Erro ao salvar os dados.');
        })
        .finally(() => {
          setLoading(false);
          handleClose();
        });
    }
  };

  const handleChange = (event, _id) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, [_id]: value }));
  };

  const handleKeyDown = (event) => {
    // Bloqueia a vírgula (,) e o ponto (.)
    if (event.key === "," || event.key === ".") {
      event.preventDefault();
    }
  };

  const handleChangeType = (event) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, type: value }));
  };

  const handleChangeTable = (event) => {
    const { value } = event.target;
    setCurrentRow((prevRow) => ({ ...prevRow, asset_cost_id: value }));
  };

  const handleChangeBandeira = (e) => {
    const { name, value } = e.target;
    setRetailer(value);
  };

  const callFilter = async (_data = []) => {

    let _rows = _data.length > 0 ? _data : searchResults

    // Filtro inline aplicado ao array de rows
    const filteredRows = _rows.filter(
      (row) =>
      (filter === "" ||
        row.group.toLowerCase().includes(filter.toLowerCase()) || 
        row.type.toLowerCase().includes(filter.toLowerCase()) ||
        row.table_name?.toLowerCase().includes(filter.toLowerCase())) &&
        ((retailer === "Todas" ||
          row.retailer.toString().toUpperCase() === retailer.toUpperCase()))
    );

    setRows(filteredRows.sort((a, b) => {
      return (b.group === a.group) ? 0 : b.group ? 1 : -1;
    }))
  }

  useEffect(() => {
    callFilter()
  }, [filter, retailer]);

  const columns = [
    {
      field: "retailer",
      headerName: "Bandeira",
      width: 80,
      editable: false,
      renderCell: (params) => formatBandeira(params.value),
    },
    {
      field: "group",
      headerName: "Grupo",
      flex: 1
    },

    {
      field: "type",
      headerName: "Tipo",
      width: 120,
    },

    {
      field: "table_name",
      headerName: "Tabela de Custos",
      width: 200,
    },

    {
      field: "qty_min",
      headerName: "Qtd. Mín.",
      width: 100,
      type: 'number'
    },

    {
      field: "qty_max",
      headerName: "Qtd. Máx.",
      width: 100,
      type: 'number'
    },

    {
      field: "multiplier",
      headerName: "Multiplicador",
      width: 100,
      type: 'number'
    },

    {
      field: "actions",
      headerName: "Ações",
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={() => handleEditClick(params.row)}
          >
            <EditIcon />
          </IconButton>
          {/* <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => showConfirmation(params.row.id, params.row.title)}
          >
            <DeleteIcon />
          </IconButton> */}
        </>
      ),
    },
  ];

  const handleInputCurrency = (values) => {
    const { value } = values;
    setCurrentRow((prevRow) => ({ ...prevRow, 'base_price': value }));
  };

  const handleInputNumChange = (e, id) => {
    const { value } = e.target;
    console.log(e)
    setCurrentRow((prevRow) => ({ ...prevRow, [id]: value }));
  };

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const fetchAll = async () => {
    console.log("Loading...");

    setLoading(true);

    try {
      axios
        .get(`${apiUrl}/api/v1/asset-type`)
        .then((response) => {
          if (apiUrl.includes("/localhost")) console.log("data", response.data);
          setSearchResults(response.data);
          setRows(response.data);
          callFilter(response.data)
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            console.error("Registro não encontrado");
            setSearchResults([]);
            setRows([]);
          } else {
            toast.error("Erro ao acessar API");
            console.error("Erro ao buscar os dados", error.toString());
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      toast.error("Error" + error);
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching...");

    const fetch = async () => {
      await fetchAll();
      await fetchAssetCostTables();
    };

    fetch();
  }, []);

  return (
    <React.Fragment>
      <h2>Grupos de Ativos</h2>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "50px",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "50px",
            alignItems: "center",
          }}
        >
          <div style={{ width: '300px', marginRight: '10px' }}>
            <FormControl fullWidth sx={{ marginBottom: "8px" }}>
            <InputLabel id="lblRetailer">Bandeira</InputLabel>
            <Select
              fullWidth
              value={retailer}
              labelId="lblRetailer"
              id="cmbRetailer"
              name="retailer"
              label="Bandeira"
              onChange={handleChangeBandeira}
            >
              <MenuItem key="all" value="Todas">Todas</MenuItem>

              {BANDEIRAS.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div style={{ width: '300px' }}>
          <FormControl fullWidth sx={{ marginBottom: "10px" }}>
            <TextField
              margin="dense"
              name="id"
              label="Busca"
              type="text"
              onChange={(e) => setFilter(e.target.value)}
            />
          </FormControl>
        </div>
        </div>

        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ width: "200px" }}
          startIcon={<AddIcon />}
          onClick={() => {
            handleNewClick();
          }}
        >
          Novo
        </Button>
      </div>

      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={loading ? [] : rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15,
              },
            },
          }}
          pageSizeOptions={[15, 50]}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          loading={loading}
          localeText={{
            noRowsLabel: "Nada encontrado",
          }}
          rowHeight={30} 
          sx={{
            ".MuiDataGrid-overlayWrap": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapperInner": {
              height: "55px !important",
              fontWeight: "bold",
            },
            ".MuiDataGrid-overlayWrapper": {
              height: "55px !important",
              fontWeight: "bold",
            },
          }}
        />
        {/* {loading && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            }}
          >
            <CircularProgress />
          </Box>
        )} */}
      </Box>

      <Dialog
        open={open}
        new={newDialog}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>{newDialog ? "Novo" : "Editar"} Tipo de Ativo</DialogTitle>
        <DialogContent>
          {currentRow && (
            <>
              <Grid
                container
                direction={'column'}
                style={{ marginBottom: "0px" }}
              >
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      minWidth: 550,
                    }}
                    error={!!errors.title}
                  >
                    <TextField
                      margin="dense"
                      name="group"
                      label="Grupo"
                      type="text"
                      fullWidth
                      disabled={!newDialog}
                      value={currentRow.group}
                      error={!!errors.group}
                      // {...register("title", {
                      //   required: "Campo Obrigatório",
                      // })}
                      onChange={(e) => handleChange(e, "group")}
                    />
                    {errors.group && (
                      <FormHelperText>{errors.group.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "9px",
                      marginBottom: "5px",
                      minWidth: 300,
                    }}
                    error={!!errors.retailer}
                  >
                    <InputLabel id="demo-multiple-name-label">
                      Tipo *
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // {...register("type", {
                      //   required: "*",
                      // })}
                      disabled={!newDialog}
                      value={currentRow.type}
                      defaultValue={""}
                      onChange={(e) => handleChangeType(e)}
                      input={<OutlinedInput label="Tipo *" />}
                    >
                      {vet_tipos.map((name) => (
                        <MenuItem key={name} value={name}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.type && (
                      <FormHelperText>{errors.type.message}</FormHelperText>
                    )}
                  </FormControl>
                
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      marginLeft: "0px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      minWidth: 120,
                    }}
                    error={!!errors.qty_min}
                  >
                    <TextField
                      name="qty_min"
                      label={'Qtd. Mín.'}
                      fullWidth
                      type="number"
                      value={currentRow.qty_min}
                      error={!!errors.qty_min}
                      onChange={(e) => handleInputNumChange(e, 'qty_min')}
                    />

                    {errors.qty_min && (
                      <FormHelperText>
                        {errors.qty_min.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    sx={{
                      marginLeft: "20px",
                      marginTop: "5px",
                      marginBottom: "5px",
                      minWidth: 120,
                    }}
                    error={!!errors.qty_max}
                  >
                    <TextField
                      name="qty_max"
                      label={'Qtd. Máx.'}
                      fullWidth
                      type="number"
                      value={currentRow.qty_max}
                      error={!!errors.qty_max}
                      onChange={(e) => handleInputNumChange(e, 'qty_max')}
                    />

                    {errors.qty_max && (
                      <FormHelperText>
                        {errors.qty_max.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                </Grid>
                
                <Grid item xs={12}>

                  <FormControl
                    sx={{
                      marginTop: "5px",
                      marginBottom: "5px",
                      minWidth: 120,
                    }}
                    error={!!errors.multiplier}
                  >
                    <TextField
                      name="multiplier"
                      label={'Multiplicador'}
                      fullWidth
                      type="number"
                      value={currentRow.multiplier}
                      error={!!errors.multiplier}
                      onChange={(e) => handleInputNumChange(e, 'multiplier')}
                    />

                    {errors.multiplier && (
                      <FormHelperText>
                        {errors.multiplier.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                </Grid>

                <Grid item xs={12}>
                  
                  <FormControl
                    sx={{
                      marginTop: "5px",
                      marginBottom: "5px",
                    }}
                    error={!!errors.asset_cost_id}
                    fullWidth
                  >
                    <InputLabel id="lbldropcustos">
                      Tabela de Custos *
                    </InputLabel>
                    <Select
                      labelId="lbldropcustos"
                      id="dropcustos"
                      {...register("asset_cost_id", {
                        required: "*",
                      })}
                      value={currentRow.asset_cost_id}
                      defaultValue={""}
                      onChange={(e) => handleChangeTable(e)}
                      input={<OutlinedInput label="Tabela de Custos *" />}
                    >
                      {assetCostList.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.table_name}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.type && (
                      <FormHelperText>{errors.asset_cost_id.message}</FormHelperText>
                    )}
                  </FormControl>

                </Grid>

              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          {hasFullPermission(user) && <Button onClick={handleSubmit(handleSave)} color="primary">
            Salvar
          </Button>}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AssetTypes;
