import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

import {
  CircularProgress,
} from "@mui/material";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div style={{fontWeight: 'bold', fontSize: '12px', marginTop: '20px'}}>Carregando...</div>; 
  }

  return user ? children : <Navigate to="/" />;
};

export default PrivateRoute;
